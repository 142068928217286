<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Liste des cheques</h4>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15"> 
                  <div class="clearfix">
                    <button
                      type="button"
                      class="waves-effect waves-light btn mb-5 bg-gradient-warning"
                      @click="addModal()"
                    >
                      + Ajouter
                    </button> 
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="inscriptions"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr> 
                            <th>ID</th>
                            <th>Banque</th>
                            <th>Montant</th>
                            <th>Proprietaire</th>
                            <th>Num chèque</th>
                            <th>Echeance</th>
                            <th>Garantie</th>
                            <th>date encaissement</th>
                            <th>date remise banque</th>
                            <th>date restitution</th>
                            <th>date retour impaye</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->
 
        <!-- modal Supprimer cheque -->
        <div
          class="modal fade"
          id="DeleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer cheque</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeleteModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce cheque?</h4>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeleteModal">
                  Non
                </button>
                <button @click="Delete" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer cheque -->

        <!-- modal encaisser cheque -->
        <div
          class="modal fade"
          id="EncaisserModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Encaisser cheque</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeEncaisserModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4><input name="checkM" style="position: unset;opacity: 1;width: 15px;height: 15px;" type="radio" :value="1" v-model="formEncaisser.checked">Encaisser tout le montant.</h4>
                <h4><input name="checkM" style="position: unset;opacity: 1;width: 15px;height: 15px;" type="radio" :value="2" v-model="formEncaisser.checked">Encaisser une partie du montant.</h4>
                <div v-if="formEncaisser.checked==2" class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Montant</label>
                      <div :class="v.formEncaisser.montant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        
                        <input
                          type="number"
                          placeholder="Montant..."
                          class="form-control"
                          v-model="formEncaisser.montant"
                          @input="v.formEncaisser.montant.$touch()"
                        />
                      </div>
                      <div class="input-errors" v-for="error of v.formEncaisser.montant.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Echeance</label>
                      <div :class="v.formEncaisser.Echeance.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        
                        <input
                          type="date"
                          placeholder="Echeance..."
                          class="form-control"
                          v-model="formEncaisser.Echeance"
                          @input="v.formEncaisser.Echeance.$touch()"
                        />
                      </div>
                      <div class="input-errors" v-for="error of v.formEncaisser.Echeance.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeEncaisserModal">
                  Non
                </button>
                <button @click="Encaisser" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal encaisser cheque -->

        <!-- modal Remettre cheque -->
        <div
          class="modal fade"
          id="RemttreModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Remettre a la banque</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeRemttreModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row"> 
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Banque:</label>
                      <div :class="v.formRemettre.banque.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        
                        <select class="form-select" v-model="formRemettre.banque">
                          <option :value="null" disabled>Choisie Compte</option> 
                          <option
                          :value="b.id"
                            v-for="b in Utilisateurs"
                            :key="b.id"
                            >{{b.email}}</option> 
                        </select>
                      </div>
                      <div class="input-errors" v-for="error of v.formRemettre.banque.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeRemttreModal">
                  Non
                </button>
                <button @click="RemmetreBanque" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Remettre cheque -->

        <!-- modal Restituer cheque -->
        <div
          class="modal fade"
          id="RestituerModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Restituer cheque</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeRestituerModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment Restituer ce cheque?</h4>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeRestituerModal">
                  Non
                </button>
                <button @click="Restituer" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Restituer cheque -->

        <!-- modal Retour cheque -->
        <div
          class="modal fade"
          id="RetourModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Retour impaye</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeRetourModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment effectuer cette action?</h4>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeRetourModal">
                  Non
                </button>
                <button @click="Impayer" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Retour cheque -->

        <!-- modal Annulation cheque -->
        <div
          class="modal fade"
          id="AnnulationModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Annulation</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAnnulationModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment effectuer cette action?</h4>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAnnulationModal">
                  Non
                </button>
                <button @click="Annulation" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Annulation cheque -->

        <!-- modal Ajouter cheque -->
        <div
          class="modal fade"
          id="AddChequeModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter paiement chèque </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Banque</label>
                              <div :class="v.formAddCheque.banque.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <select class="form-select" v-model="formAddCheque.banque">
                                  <option :value="null" disabled>Choisie Banque</option> 
                                  <option
                                  :value="b.idbanque"
                                   v-for="b in Banques"
                                   :key="b.idbanque"
                                   >{{b.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCheque.banque.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Proprietaire</label>
                              <div :class="v.formAddCheque.proprietaire.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="Proprietaire..."
                                  class="form-control"
                                  v-model="formAddCheque.proprietaire"
                                  @input="v.formAddCheque.proprietaire.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCheque.proprietaire.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Num chèque</label>
                              <div class="input-group mb-15">
                                <input
                                  type="text"
                                  placeholder="Numéro de chèque..."
                                  class="form-control"
                                  v-model="formAddCheque.num"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div :class="v.formAddCheque.montant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="formAddCheque.montant"
                                  @input="v.formAddCheque.montant.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCheque.montant.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Echeance</label>
                              <div :class="v.formAddCheque.Echeance.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date"
                                  placeholder="Echeance..."
                                  class="form-control"
                                  v-model="formAddCheque.Echeance"
                                  @input="v.formAddCheque.Echeance.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCheque.Echeance.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group" style="position:relative">
                              <input
                                type="checkbox"
                                style="left:0;width:18px;height:18px;z-index: 100;cursor:pointer;"
                                v-model="formAddCheque.garantie"
                                @input="v.formAddCheque.garantie.$touch()"
                              />
                              <label class="form-label">Garantie</label>
                                
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalAdd"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="AddCheque">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Ajouter cheque -->

      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf , maxValue , minValue } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import ChequeApi from "../../../api/cheque";
import moment from "moment";
import CaisseApi from "../../../api/caisse";
import UserApi from "../../../api/user";
import banqueApi from "../../../api/banque";

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
  },
  data() {
    return { 
      loader:false,
      Cheques:[], 
      Utilisateurs:[], 
      Banques:[],
      url:null,
      selectedId:"",
      
      formAddCheque:{
        banque:null,
        proprietaire:"",
        num:"",
        Echeance:"",
        montant:"",
        garantie:false,
      },
      formEncaisser:{
        checked:1,
        Imontant:0,
        montant:100,
        Echeance:'',
        proprietaire:'',
        idbanque:'',
        garantie:'',
      },
      formAnnuler:{
        id:null,
        type:null,
        proprietaire:"",
        montant:"",
        user:null,
      },
      formRemettre:{
        montant:'',
        proprietaire:'',
        banque:null,
      },
    };
  },
  validations () {
    return { 
      formAddCheque:{
        banque:{ required },
        proprietaire:{ required },
        Echeance:{ required },
        montant:{ required },
      },
      formRemettre:{
        banque:{ required },
      },
      formEncaisser:{
        montant:{ required: requiredIf(function(){
          return this.formEncaisser.checked==2 
        }),
        minValue : minValue(100),
        maxValue : maxValue(this.formEncaisser.Imontant),
        },
        Echeance:{ required: requiredIf(function(){
          return this.formEncaisser.checked==2 
        }) },
      },
    }
  },
  mounted() {
    this.datatable();
    banqueApi.getAll((r) => {
      this.Banques=r.data; 
    });
    UserApi.getAll((r) => {
      this.Utilisateurs=[];
      for(const u of r.data){
        if(u.administrateur == 4){
          this.Utilisateurs.push(u);
        }
      }
    })
  },
  methods: {
    async loadData() {
      this.loader = true;
      await ChequeApi.getAll((r) => {
        if(r!=null){
          console.log(r)
          if(r.success==true){
            this.Cheques = []; 
            for(const d of r.data){
              console.log(d.centre_idcentre , localStorage.getItem('centre'))
              if(d.centre_idcentre == localStorage.getItem('centre'))
                this.Cheques.push({
                  id:d.idcheque,
                  proprietaire:d.proprietaire,
                  num_cheque:d.num_cheque,
                  montant:d.montant,
                  garantie:d.garantie==1?"oui":"non",
                  echeance:d.echeance,
                  date_encaissement:d["date-encaissement"],
                  date_restitution:d["date-restitution"],
                  date_remise_banque:d["date-remise-banque"],
                  date_retour_impaye:d["date-retour-impaye"],
                  banque:d.designation,
                  idbanque:d.banque_idbanque,
                  remise_banque:d.remise_banque,
                })
            }
            console.log(this.Cheques)
            var t = $("#inscriptions").DataTable();
            t.clear().rows.add(this.Cheques).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#inscriptions").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          data: this.Cheques,
          order:[[0,"desc"]],
          columns: [ 
            {data: "id"},
            {data: "banque"},
            {data:"montant"},
            {data:"proprietaire"},
            {data:"num_cheque"},
            {data:"echeance"},
            {data:"garantie"},
            {data:"date_encaissement",
              render:function(data){
                if(data==null)
                  return '<button class="waves-effect btn  btn-info btnShowEncaisse"> Encaisser </button>';
                else
                  return data + '<br><button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger btnShowUnEncaisse"><i title="Supprimer" style="cursor:pointer;" class="ti-close" aria-hidden="true"></i></button>';
              }
            },
            {data:"date_remise_banque",
              render:function(data){
                if(data==null)
                  return '<button class="waves-effect btn  btn-info btnShowRemttre"> Remettre a la banque </button>';
                else
                  return data + '<br><button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger btnShowUnRemttre"><i title="Supprimer" style="cursor:pointer;" class="ti-close" aria-hidden="true"></i></button>';
              }
            },
            {data:"date_restitution",
              render:function(data){
                if(data==null)
                  return '<button class="waves-effect btn  btn-info btnShowRestituer"> Restituer </button>';
                else
                  return data + '<br><button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger btnShowUnRestituer"><i title="Supprimer" style="cursor:pointer;" class="ti-close" aria-hidden="true"></i></button>';
              }
            },
            {data:"date_retour_impaye",
              render:function(data){
                if(data==null)
                  return '<button class="waves-effect btn  btn-info btnShowRoutour"> Retour impaye </button>';
                else
                  return data + '<br><button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger btnShowUnRoutour"><i title="Supprimer" style="cursor:pointer;" class="ti-close" aria-hidden="true"></i></button>';
              }
            },
            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger btnDelete"><i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i></button></div>',
            },
          ],
        });
      });
      const thisVue = this; 

      //for Encaisser
      $("#inscriptions tbody ").on("click", ".btnShowEncaisse", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.selectedId = data["id"];
        thisVue.formEncaisser.checked = 1;
        thisVue.formEncaisser.Imontant = data["montant"];
        thisVue.formEncaisser.montant = data["montant"];
        thisVue.formEncaisser.proprietaire = data["proprietaire"];
        thisVue.formEncaisser.idbanque = data["idbanque"];
        thisVue.formEncaisser.garantie = data["garantie"];
        thisVue.formEncaisser.Echeance = "";
        thisVue.ShowEncaisserModal();
      });

      //for UnEncaisser
      $("#inscriptions tbody ").on("click", ".btnShowUnEncaisse", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formAnnuler.proprietaire = data["proprietaire"];
        thisVue.formAnnuler.montant = data["montant"];
        thisVue.AnnulationModal(data["id"],1);
      });

      //for UnRemettre
      $("#inscriptions tbody ").on("click", ".btnShowUnRemttre", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formAnnuler.proprietaire = data["proprietaire"];
        thisVue.formAnnuler.montant = data["montant"];
        thisVue.formAnnuler.user = data["remise_banque"];
        if(data["remise_banque"])
          thisVue.AnnulationModal(data["id"],2);
      });

      //for UnRestituer
      $("#inscriptions tbody ").on("click", ".btnShowUnRestituer", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.AnnulationModal(data["id"],3);
      });

      //for UnRetour
      $("#inscriptions tbody ").on("click", ".btnShowUnRoutour", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.AnnulationModal(data["id"],4);
      });

      //for Remettre
      $("#inscriptions tbody ").on("click", ".btnShowRemttre", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.selectedId = data["id"];
        thisVue.formRemettre.banque = null;
        thisVue.formRemettre.proprietaire = data["proprietaire"];
        thisVue.formRemettre.montant = data["montant"];
        thisVue.ShowRemttreModal();
      });

      //for Restituer
      $("#inscriptions tbody ").on("click", ".btnShowRestituer", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.selectedId = data["id"];
        thisVue.ShowRestituerModal();
      });

      //for Retour
      $("#inscriptions tbody ").on("click", ".btnShowRoutour", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.selectedId = data["id"];
        thisVue.ShowRetourModal();
      });

      //for delete
      $("#inscriptions tbody ").on("click", ".btnDelete", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.selectedId = data["id"];
        $("#DeleteModal").modal();
        thisVue.DeleteModal();
      });
    }, 
    Annulation(){
      if(this.formAnnuler.type == 1){
        ChequeApi.Encaisser({
          id:this.formAnnuler.id, 
          date:null, 
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              CaisseApi.add({
                recette:false,   
                libelle:"Annulation d'encaissement cheque."+this.formAnnuler.id+", " + this.formAnnuler.proprietaire,   
                montant:this.formAnnuler.montant,   
                utilisateur_idutilisateur:localStorage.getItem("id"),   
                centre:localStorage.getItem("centre"),   
              },(rr) => {
                if(rr!=null)
                {
                  if(rr.success==true){
                    this.loadData();
                    this.closeAnnulationModal();
                  }
                }
              });
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
      if(this.formAnnuler.type == 2){
        ChequeApi.RemmetreBanque({
          id:this.formAnnuler.id, 
          date:null, 
          banque:null, 
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              CaisseApi.add({
                recette:false,   
                libelle:"Annulation de cheque."+this.formAnnuler.id+", " + this.formAnnuler.proprietaire,   
                montant:this.formAnnuler.montant,   
                utilisateur_idutilisateur:this.formAnnuler.user,   
                centre:localStorage.getItem("centre"),   
              },(rr) => {
                if(rr!=null)
                {
                  if(rr.success==true){
                    this.loadData();
                    this.closeAnnulationModal();
                  }
                }
              });
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
      if(this.formAnnuler.type == 3){
        ChequeApi.Restituer({
          id:this.formAnnuler.id, 
          date:null, 
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              this.loadData();
              this.closeAnnulationModal();
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
      if(this.formAnnuler.type == 4){
        ChequeApi.Impayer({
          id:this.formAnnuler.id, 
          date:null, 
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              this.loadData();
              this.closeAnnulationModal();
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
    },
    Encaisser(){
      if(this.formEncaisser.checked == 1 || parseFloat(this.formEncaisser.montant) == parseFloat(this.formEncaisser.Imontant)){
        $("#globalLoader").removeClass("H");
        $("#globalLoader").addClass("S");
        ChequeApi.Encaisser({
          id:this.selectedId, 
          date:moment().format('YYYY-MM-DD'), 
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              CaisseApi.add({
                recette:true,   
                libelle:"Encaissement cheque."+this.selectedId+", " + this.formEncaisser.proprietaire,   
                montant:this.formEncaisser.Imontant,   
                utilisateur_idutilisateur:localStorage.getItem("id"),   
                centre:localStorage.getItem("centre"),   
              },(rr) => {
                if(rr!=null)
                {
                  if(rr.success==true){
                    this.loadData();
                    this.closeEncaisserModal();
                    $("#globalLoader").removeClass("S");
                    $("#globalLoader").addClass("H");
                  }
                }
              });
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
      else if(this.formEncaisser.checked == 2 && parseFloat(this.formEncaisser.montant) < parseFloat(this.formEncaisser.Imontant)){
        if(this.v.formEncaisser.$invalid){
          this.v.formEncaisser.$touch();
        }
        else{
          $("#globalLoader").removeClass("H");
          $("#globalLoader").addClass("S");
          ChequeApi.Encaisser2({
            id:this.selectedId, 
            montant:this.formEncaisser.montant, 
            date:moment().format('YYYY-MM-DD'), 
          },(r) => {
            if(r!=null)
            {
              if(r.success==true){
                CaisseApi.add({
                  recette:true,   
                  libelle:"Encaissement cheque."+this.selectedId+", " + this.formEncaisser.proprietaire,   
                  montant:this.formEncaisser.montant,   
                  utilisateur_idutilisateur:localStorage.getItem("id"),   
                  centre:localStorage.getItem("centre"),   
                },(rr) => {
                  if(rr!=null)
                  {
                    if(rr.success==true){
                      ChequeApi.add({
                        proprietaire:this.formEncaisser.proprietaire,
                        montant: parseFloat(this.formEncaisser.Imontant) - parseFloat(this.formEncaisser.montant),
                        garantie:this.formEncaisser.garantie=='oui'?true:false,
                        echeance:this.formEncaisser.Echeance,
                        idbanque:this.formEncaisser.idbanque,
                        centre_idcentre:localStorage.getItem('centre'),
                      },async(r)=>{
                        if(r){
                          this.loadData();
                          this.closeEncaisserModal();
                          $("#globalLoader").removeClass("S");
                          $("#globalLoader").addClass("H");
                        }
                        else{
                          console.log("error",r.msg)
                        }
                      })
                    }
                  }
                });
              }
              else{
                console.log("error",r.msg)
              }
            }
          });
        }
      }
    },
    RemmetreBanque(){
      
      $("#globalLoader").removeClass("H");
      $("#globalLoader").addClass("S");
      ChequeApi.RemmetreBanque({
        id:this.selectedId, 
        date:moment().format('YYYY-MM-DD'), 
        banque:this.formRemettre.banque,
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
             CaisseApi.add({
                recette:true,   
                libelle:"Remmetre cheque."+this.selectedId+", " + this.formRemettre.proprietaire,   
                montant:this.formRemettre.montant,   
                utilisateur_idutilisateur:this.formRemettre.banque,   
                centre:localStorage.getItem("centre"),   
              },(rr) => {
                if(rr!=null)
                {
                  if(rr.success==true){
                    this.loadData();
                    this.closeRemttreModal();
                    $("#globalLoader").removeClass("S");
                    $("#globalLoader").addClass("H");
                  }
                }
              });
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    Restituer(){
      ChequeApi.Restituer({
        id:this.selectedId, 
        date:moment().format('YYYY-MM-DD'), 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.closeRestituerModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    Impayer(){
      ChequeApi.Impayer({
        id:this.selectedId, 
        date:moment().format('YYYY-MM-DD'), 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.closeRetourModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    AddCheque(){
      if(this.v.formAddCheque.$invalid){
        this.v.formAddCheque.$touch();
      }
      else{
        ChequeApi.add({
          proprietaire:this.formAddCheque.proprietaire,
          num:this.formAddCheque.num,
          montant:this.formAddCheque.montant,
          garantie:this.formAddCheque.garantie,
          echeance:this.formAddCheque.Echeance,
          idbanque:this.formAddCheque.banque,
          centre_idcentre:localStorage.getItem('centre'),
        },async(r)=>{
          if(r){
            this.loadData();
            this.closeModalAdd();
          }
          else{
            console.log("error",r.msg)
          }
        })
      }
    },
    Delete(){
      ChequeApi.delete({
        id:this.selectedId, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.closeDeleteModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    addModal() {
      $("#AddChequeModal").modal("show");
    },
    closeModalAdd() {
      $("#AddChequeModal").modal("hide");
    },
    ShowEncaisserModal(){ 
      $("#EncaisserModal").modal("show");
    },  
    closeEncaisserModal(){ 
      $("#EncaisserModal").modal("hide");
    },
    ShowRemttreModal(){ 
      $("#RemttreModal").modal("show");
    },  
    closeRemttreModal(){ 
      $("#RemttreModal").modal("hide");
    },
    ShowRestituerModal(){ 
      $("#RestituerModal").modal("show");
    },  
    closeRestituerModal(){ 
      $("#RestituerModal").modal("hide");
    },
    ShowRetourModal(){ 
      $("#RetourModal").modal("show");
    },  
    closeRetourModal(){ 
      $("#RetourModal").modal("hide");
    },
    DeleteModal(){ 
      $("#DeleteModal").modal("show");
    },  
    closeDeleteModal(){ 
      $("#DeleteModal").modal("hide");
    },
    AnnulationModal(id,type){
      this.formAnnuler.id=id; 
      this.formAnnuler.type=type; 
      $("#AnnulationModal").modal("show");
    },  
    closeAnnulationModal(){ 
      $("#AnnulationModal").modal("hide");
    },
    ClearModal(){},  
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
