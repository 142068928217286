<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Paramètres des Groupes</h4>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15">
                  <div class="clearfix">
                    <button
                      type="button"
                      class="waves-effect waves-light btn mb-5 bg-gradient-warning"
                      @click="addModal()"
                    >
                      + Ajouter
                    </button>
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="groupes"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>N Gr</th>
                            <th>Annee</th> 
                            <th>Matiere</th> 
                            <th>Niveau</th> 
                            <th>Cadence hebdo</th> 
                            <th>Prof</th> 
                            <th>Documentation</th> 
                            <th>Salle</th> 
                            <th>Centre</th> 
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->

        <!-- modal Ajouter groupe -->
        <div
          class="modal fade"
          id="AddModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter groupe</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row">

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Annee :</label>
                              <div :class="v.formA.AnneeId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.AnneeId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idannee']"
                                    v-for="m in Annees"
                                    :key="m['idannee']"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.AnneeId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Centre :</label>
                              <div :class="v.formA.CentreId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.CentreId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['id']"
                                    v-for="m in Centres"
                                    :key="m['id']"
                                    >{{m.nom}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.CentreId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Matieres :</label>
                              <div :class="v.formA.MatiereId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.MatiereId" @change="chooseProfA">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idmatiere']"
                                    v-for="m in Matieres"
                                    :key="m['idmatiere']"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.MatiereId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Niveau :</label>
                              <div :class="v.formA.Niveau.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                 <input
                                  type="text"
                                  placeholder="Niveau..."
                                  class="form-control"
                                  v-model="formA.Niveau"
                                  @input="v.formA.Niveau.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.Niveau.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Cadences :</label>
                              <div :class="v.formA.CadenceId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.CadenceId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idcadence-hebdo']"
                                    v-for="m in Cadences"
                                    :key="m['idcadence-hebdo']"
                                    >{{m['nbr-heures'] + " H"}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.CadenceId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Documentations :</label>
                              <div :class="v.formA.DocumentationId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.DocumentationId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['iddocumentation']"
                                    v-for="m in Documentations"
                                    :key="m['iddocumentation']"
                                    >{{m['titre']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.DocumentationId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Prof par defaut :</label>
                              <div :class="v.formA.ProfId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.ProfId" @change="chooseProfA">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idprof']"
                                    v-for="m in Profs"
                                    :key="m['idprof']"
                                    :style="m.centre_idcentre!=formA.CentreId?'display:none':''"
                                    >{{m['nom'] + " " + m['prenom']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.ProfId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Prof Tarif :</label>
                              <div :class="v.formA.TarifId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.TarifId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idtarif-prof-matiere']"
                                    v-for="m in Tarifs"
                                    :key="m['idtarif-prof-matiere']"
                                    >{{m['montant']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.TarifId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Salles :</label>
                              <div :class="v.formA.SalleId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.SalleId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idsalle']"
                                    v-for="m in Salles"
                                    :key="m['idsalle']"
                                    :style="m.centre_idcentre!=formA.CentreId?'display:none':''"
                                    >{{m['designation']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.SalleId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <hr>
                        <!--//////////////////////////////////////////////////////////////-->
                        <div>
                          <h4 class="form-label">Categorie :</h4>
                          <div class="row">
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">Categorie</label>
                              <div :class="v.formCatsA.cat.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formCatsA.cat">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idcategorie']+'|'+m['designation']"
                                    v-for="m in Categories"
                                    :key="m['idcategorie']"
                                    :style="ajouterCatsList.map(v=>v.idcategorie).indexOf(m['idcategorie'].toString())!=-1?'display:none':''"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formCatsA.cat.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-2">
                            <br>
                            <button type="button" class="btn btn-primary float-end" @click="addCatsJson">
                              Ajouter
                            </button>
                          </div>
                          </div>
                          <div style="overflow-x: auto;max-height: 500px;">
                            <table
                              class="table table-bordered table-striped dataTable"
                            >
                              <thead>
                                <tr>
                                  <th>Categorie</th>
                                </tr>
                              </thead>
                              <tbody>
                                  <tr v-if="ajouterCatsList.length==0"><td colspan="2" align="center">Ajouter Categorie</td></tr>
                                  <tr v-for="d,i of ajouterCatsList" :key="i">
                                    <td>{{d.designation}}</td>
                                    <td>
                                      <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="deleteCatsJson(i)">
                                        <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                                      </button>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!--//////////////////////////////////////////////////////////////-->
                        <hr>
                        <!--//////////////////////////////////////////////////////////////-->
                        <div>
                          <h4 class="form-label">Horaire :</h4>
                          <div class="row">
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">Horaire</label>
                              <div :class="v.formHorairesA.val.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formHorairesA.val">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idhoraire']+'|'+weekDays[m['jour']] +' ' + m['heure-debut'] +':'+m['min-debut'] + ' - ' + m['heure-fin'] +':'+m['min-fin']"
                                    v-for="m in Horaires"
                                    :key="m['idhoraire']"
                                    :style="ajouterHorairesList.map(v=>v.idhoraire).indexOf(m['idhoraire'].toString())!=-1?'display:none':''"
                                    >{{weekDays[m['jour']] +' ' + m['heure-debut'] +':'+m['min-debut'] + ' - ' + m['heure-fin'] +':'+m['min-fin']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formHorairesA.val.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-2">
                            <br>
                            <button type="button" class="btn btn-primary float-end" @click="addHorairesJson">
                              Ajouter
                            </button>
                          </div>
                          </div>
                          <div style="overflow-x: auto;max-height: 500px;">
                            <table
                              class="table table-bordered table-striped dataTable"
                            >
                              <thead>
                                <tr>
                                  <th>Horaire</th>
                                </tr>
                              </thead>
                              <tbody>
                                  <tr v-if="ajouterHorairesList.length==0"><td colspan="2" align="center">Ajouter Horaire</td></tr>
                                  <tr v-for="d,i of ajouterHorairesList" :key="i">
                                    <td>{{d.designation}}</td>
                                    <td>
                                      <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="deleteHorairesJson(i)">
                                        <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                                      </button>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!--//////////////////////////////////////////////////////////////-->
                        <hr>
                        <!--//////////////////////////////////////////////////////////////-->
                        <div>
                          <h4 class="form-label">Periode :</h4>
                          <div class="row">
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">Periode</label>
                              <div :class="v.formPeriodesA.val.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formPeriodesA.val">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idperiode']+'|'+m['designation']"
                                    v-for="m in Periodes"
                                    :key="m['idperiode']"
                                    :style="ajouterPeriodesList.map(v=>v.idperiode).indexOf(m['idperiode'].toString())!=-1?'display:none':''"
                                    >{{m['designation']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formPeriodesA.val.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">nombre d'heures</label>
                              <div :class="v.formPeriodesA.nbr_heures.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <input
                                  type="number"
                                  min="0"
                                  placeholder="nombre d'heures..."
                                  class="form-control"
                                  v-model="formPeriodesA.nbr_heures"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formPeriodesA.nbr_heures.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-2">
                            <br>
                            <button type="button" class="btn btn-primary float-end" @click="addPeriodesJson">
                              Ajouter
                            </button>
                          </div>
                          </div>
                          <div style="overflow-x: auto;max-height: 500px;">
                            <table
                              class="table table-bordered table-striped dataTable"
                            >
                              <thead>
                                <tr>
                                  <th>Periode</th>
                                  <th>nombre d'heures</th>
                                </tr>
                              </thead>
                              <tbody>
                                  <tr v-if="ajouterPeriodesList.length==0"><td colspan="2" align="center">Ajouter Periode</td></tr>
                                  <tr v-for="d,i of ajouterPeriodesList" :key="i">
                                    <td>{{d.designation}}</td>
                                    <td>{{d.nbr_heures}}</td>
                                    <td>
                                      <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="deletePeriodesJson(i)">
                                        <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                                      </button>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!--//////////////////////////////////////////////////////////////-->
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalAdd"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addgroupe" @click="save">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Ajouter groupe -->

        <!-- modal Modifier groupe -->
        <div
          class="modal fade"
          id="UpdateModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">modifier groupe</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body">
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Annee :</label>
                              <div :class="v.formU.AnneeId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.AnneeId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idannee']"
                                    v-for="m in Annees"
                                    :key="m['idannee']"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.AnneeId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Centre :</label>
                              <div :class="v.formU.CentreId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.CentreId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['id']"
                                    v-for="m in Centres"
                                    :key="m['id']"
                                    >{{m.nom}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.CentreId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Matieres :</label>
                              <div :class="v.formU.MatiereId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.MatiereId" @change="chooseProfU">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idmatiere']"
                                    v-for="m in Matieres"
                                    :key="m['idmatiere']"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.MatiereId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Niveau :</label>
                              <div :class="v.formU.Niveau.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                 <input
                                  type="text"
                                  placeholder="Niveau..."
                                  class="form-control"
                                  v-model="formU.Niveau"
                                  @input="v.formU.Niveau.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.Niveau.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Cadences :</label>
                              <div :class="v.formU.CadenceId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.CadenceId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idcadence-hebdo']"
                                    v-for="m in Cadences"
                                    :key="m['idcadence-hebdo']"
                                    >{{m['nbr-heures'] + " H"}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.CadenceId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Documentations :</label>
                              <div :class="v.formU.DocumentationId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.DocumentationId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['iddocumentation']"
                                    v-for="m in Documentations"
                                    :key="m['iddocumentation']"
                                    >{{m['titre']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.DocumentationId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Prof par defaut :</label>
                              <div :class="v.formU.ProfId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.ProfId" @change="chooseProfU">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idprof']"
                                    v-for="m in Profs"
                                    :key="m['idprof']"
                                    :style="m.centre_idcentre!=formU.CentreId?'display:none':''"
                                    >{{m['nom'] + " " + m['prenom']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.ProfId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Prof Tarif :</label>
                              <div :class="v.formU.TarifId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.TarifId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idtarif-prof-matiere']"
                                    v-for="m in TarifsU"
                                    :key="m['idtarif-prof-matiere']"
                                    >{{m['montant']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.TarifId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Salles :</label>
                              <div :class="v.formU.SalleId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.SalleId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idsalle']"
                                    v-for="m in Salles"
                                    :key="m['idsalle']"
                                    :style="m.centre_idcentre!=formU.CentreId?'display:none':''"
                                    >{{m['designation']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.SalleId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalUpdate"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addgroupe" @click="Update">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier groupe -->

        <!-- modal Supprimer groupe -->
        <div
          class="modal fade"
          id="DeleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer groupe</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalDelete"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce groupe?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <label class="form-label">N Gr</label>
                    <input
                      type="text" 
                      class="form-control"
                      v-model="formD.idgroupe"
                    />
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalDelete">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deletegroupe" @click="Delete">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer groupe -->

        <!-- modal Categories prof -->
        <div
          class="modal fade"
          id="showCatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Categories</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowCats"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>designation</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupeCategories.length==0"><td colspan="1" align="center">Aucun periode trouve</td></tr>
                        <tr v-for="(d,i) of GroupeCategories" :key="i">
                          <td>{{d.designation}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="showDeleteCatsModal(d)" >
                              <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowCats">
                  fermer
                </button>
                <button @click="showAddCatsModal()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Categories -->

        <!-- modal add Categories prof -->
        <div
          class="modal fade"
          id="AddCatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter Categorie</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAddCatsModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Categorie</label>
                    <div :class="v.formCatsA2.idcategorie.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      <select class="form-select" v-model="formCatsA2.idcategorie">
                        <option :value="null" disabled>Choisie </option> 
                        <option
                        :value="m['idcategorie']"
                          v-for="m in Categories"
                          :key="m['idcategorie']"
                          :style="GroupeCategories.map(v=>v.idcategorie).indexOf(m['idcategorie'])!=-1?'display:none':''"
                          >{{m.designation}}</option> 
                      </select>
                    </div>
                    <div class="input-errors" v-for="error of v.formCatsA2.idcategorie.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAddCatsModal">
                  Non
                </button>
                <button @click="saveCats" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal add Categories prof -->

        <!-- modal Supprimer Categorie prof -->
        <div
          class="modal fade"
          id="DeleteCatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer Categorie</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeleteCatsModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce Categorie?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <strong class="form-label">Categorie : </strong>
                    {{formCatsD.idcategorie}}
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeleteCatsModal">
                  Non
                </button>
                <button @click="DeleteCats" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer matiers-tarif prof -->

        <!---------------------------------------------------------------------------------------------------------------------------------->

        <!-- modal Horaires prof -->
        <div
          class="modal fade"
          id="showHorairesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Horaires</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowHoraires"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupeHoraires.length==0"><td colspan="1" align="center">Aucun Horaire trouve</td></tr>
                        <tr v-for="(m,i) of GroupeHoraires" :key="i">
                          <td>{{weekDays[m['jour']] +' ' + m['heure-debut'] +':'+m['min-debut'] + ' - ' + m['heure-fin'] +':'+m['min-fin']}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="showDeleteHorairesModal(m)" >
                              <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowHoraires">
                  fermer
                </button>
                <button @click="showAddHorairesModal()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Horaires -->

        <!-- modal add Horaires prof -->
        <div
          class="modal fade"
          id="AddHorairesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter Horaire</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAddHorairesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Horaire</label>
                    <div :class="v.formHorairesA2.idhoraire.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      <select class="form-select" v-model="formHorairesA2.idhoraire">
                        <option :value="null" disabled>Choisie </option> 
                        <option
                        :value="m['idhoraire']"
                          v-for="m in Horaires"
                          :key="m['idhoraire']"
                          :style="GroupeHoraires.map(v=>v.idhoraire).indexOf(m['idhoraire'])!=-1?'display:none':''"
                          >{{weekDays[m['jour']] +' ' + m['heure-debut'] +':'+m['min-debut'] + ' - ' + m['heure-fin'] +':'+m['min-fin']}}</option> 
                      </select>
                    </div>
                    <div class="input-errors" v-for="error of v.formHorairesA2.idhoraire.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAddHorairesModal">
                  Non
                </button>
                <button @click="saveHoraires" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal add Horaires prof -->

        <!-- modal Supprimer Horaires prof -->
        <div
          class="modal fade"
          id="DeleteHorairesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer Horaire</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeleteHorairesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce Horaire?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <strong class="form-label">Horaire : </strong>
                    {{formHorairesD.idhoraire}}
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeleteHorairesModal">
                  Non
                </button>
                <button @click="DeleteHoraires" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer Horaire prof -->

        <!---------------------------------------------------------------------------------------------------------------------------------->

        <!-- modal Periodes prof -->
        <div
          class="modal fade"
          id="showPeriodesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Periodes</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowPeriodes"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>Designation</th>
                        <th>nombre d'heures</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupePeriodes.length==0"><td colspan="1" align="center">Aucun Periode trouve</td></tr>
                        <tr v-for="(m,i) of GroupePeriodes" :key="i">
                          <td>{{m.designation}}</td>
                          <td>{{m['nbr-heures']}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="showDeletePeriodesModal(m)" >
                              <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowPeriodes">
                  fermer
                </button>
                <button @click="showAddPeriodesModal()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Periodes -->

        <!-- modal add Periodes prof -->
        <div
          class="modal fade"
          id="AddPeriodesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter Periode</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAddPeriodesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Periode</label>
                    <div :class="v.formPeriodesA2.idperiode.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      <select class="form-select" v-model="formPeriodesA2.idperiode">
                        <option :value="null" disabled>Choisie </option> 
                        <option
                        :value="m['idperiode']"
                          v-for="m in Periodes"
                          :key="m['idperiode']"
                          :style="GroupePeriodes.map(v=>v.idperiode).indexOf(m['idperiode'])!=-1?'display:none':''"
                          >{{m.designation}}</option> 
                      </select>
                    </div>
                    <div class="input-errors" v-for="error of v.formPeriodesA2.idperiode.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Nombre d'heures</label>
                    <div :class="v.formPeriodesA2.nbr_heures.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      
                      <input
                        type="number"
                        min="0"
                        placeholder="Nombre d'heures..."
                        class="form-control"
                        v-model="formPeriodesA2.nbr_heures"
                        @input="v.formPeriodesA2.nbr_heures.$touch()"
                      />
                    </div>
                    <div class="input-errors" v-for="error of v.formPeriodesA2.nbr_heures.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAddPeriodesModal">
                  Non
                </button>
                <button @click="savePerdiodes" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal add Periodes prof -->

        <!-- modal Supprimer Periodes prof -->
        <div
          class="modal fade"
          id="DeletePeriodesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer Periode</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeletePeriodesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce Periode?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <strong class="form-label">Periode : </strong>
                    {{formPeriodesD.idperiode}}
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeletePeriodesModal">
                  Non
                </button>
                <button @click="DeletePeriodes" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer Periode prof -->
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import GroupeApi from "../../../api/groupe";
import AnneeApi from "../../../api/annees";
import matiereApi from "../../../api/matiere";
import cadenceApi from "../../../api/cadence";
import documentationApi from "../../../api/documentation";
import profApi from "../../../api/prof";
import salleApi from "../../../api/salle";
import categorieApi from "../../../api/categorie";
import horaireApi from "../../../api/horaire";
import CentreApi from "../../../api/centre";

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
  },
  data() {
    return { 
      weekDays:['lundi','mardi','mercredi','jeudi','vendredi','samedi','dimanche'],
      loader:false,
      ajouterCatsList:[],
      ajouterHorairesList:[],
      ajouterPeriodesList:[],
      Groupes:[],  
      GroupeCategories:[],  
      GroupeHoraires:[],  
      GroupePeriodes:[],  
      Categories:[],  
      Horaires:[],  
      Periodes:[],
      Annees:[],  
      Matieres:[],
      Cadences:[], 
      Documentations:[], 
      Centres:[],
      Tarifs:[],
      TarifsU:[],
      Profs:[], 
      Salles:[], 
      selectedId:null,
      formA:{ 
        AnneeId:null,  
        MatiereId:null,  
        Niveau:'', 
        CadenceId:null, 
        DocumentationId:null, 
        ProfId:null, 
        SalleId:null, 
        CentreId:null, 
        TarifId:null, 
      },
      formU:{
        idgroupe:'',
        AnneeId:null,  
        MatiereId:null,  
        Niveau:'', 
        CadenceId:null, 
        DocumentationId:null, 
        ProfId:null, 
        SalleId:null, 
        CentreId:null, 
        TarifId:null, 
      },
      formD:{ 
        idgroupe:'',
        AnneeId:null,  
        MatiereId:null,  
        Niveau:'', 
        CadenceId:null, 
        DocumentationId:null, 
        ProfId:null, 
        SalleId:null,  
      },
      formCatsA:{
        cat:null,
      },
      formCatsA2:{
        idcategorie:null,
      },
      formCatsD:{ 
        idcategorie:null,
      },
      formHorairesA:{
        val:null,
      },
      formHorairesA2:{
        idhoraire:null,
      },
      formHorairesD:{ 
        idhoraire:null,
      },
      formPeriodesA:{
        val:null,
        nbr_heures:null,
      },
      formPeriodesA2:{
        idperiode:null,
        nbr_heures:null,
      },
      formPeriodesD:{ 
        idperiode:null,
      },
      url:null,
    };
  },
  validations () {
    return { 
      formA:{ 
        AnneeId:{ required },  
        MatiereId:{ required },  
        Niveau:{ required }, 
        CadenceId:{ required }, 
        DocumentationId:{ required }, 
        ProfId:{ required }, 
        SalleId:{ required }, 
        CentreId:{ required }, 
        TarifId:{ required }, 
      },
      formU:{ 
        AnneeId:{ required },  
        MatiereId:{ required },  
        Niveau:{ required }, 
        CadenceId:{ required }, 
        DocumentationId:{ required }, 
        ProfId:{ required }, 
        SalleId:{ required },
        CentreId:{ required }, 
        TarifId:{ required }, 
      },
      formCatsA:{
        cat:{ required },
      },
      formCatsA2:{
        idcategorie:{ required },
      },
      formCatsD:{ 
        idcategorie:{ required },
      },
      formHorairesA:{
        val:{ required },
      },
      formHorairesA2:{
        idhoraire:{ required },
      },
      formHorairesD:{ 
        idhoraire:{ required },
      },
      formPeriodesA:{
        val:{ required },
        nbr_heures:{ required },
      },
      formPeriodesA2:{
        idperiode:{ required },
        nbr_heures:{ required },
      },
      formPeriodesD:{ 
        idperiode:{ required },
      },
    }
  },
  mounted() {
    this.datatable();
    console.log("kf") 
  },
  methods: {
    addCatsJson(){
      if(this.v.formCatsA.$invalid){
        this.v.formCatsA.$touch();
      }
      else{
        this.ajouterCatsList.push({
          idcategorie:this.formCatsA.cat.split('|')[0],
          designation:this.formCatsA.cat.split('|')[1],
        })
        this.formCatsA={
          cat:null,
        };
      }
    },
    deleteCatsJson(i){
      this.ajouterCatsList.splice(i,1);
    },
    addHorairesJson(){
      if(this.v.formHorairesA.$invalid){
        this.v.formHorairesA.$touch();
      }
      else{
        this.ajouterHorairesList.push({
          idhoraire:this.formHorairesA.val.split('|')[0],
          designation:this.formHorairesA.val.split('|')[1],
        })
        this.formHorairesA={
          val:null,
        };
      }
    },
    deleteHorairesJson(i){
      this.ajouterHorairesList.splice(i,1);
    },
    addPeriodesJson(){
      if(this.v.formPeriodesA.$invalid){
        this.v.formPeriodesA.$touch();
      }
      else{
        this.ajouterPeriodesList.push({
          idperiode:this.formPeriodesA.val.split('|')[0],
          designation:this.formPeriodesA.val.split('|')[1],
          nbr_heures:this.formPeriodesA.nbr_heures,
        })
        this.formPeriodesA={
          val:null,
          nbr_heures:null,
        };
      }
    },
    deletePeriodesJson(i){
      this.ajouterPeriodesList.splice(i,1);
    },
    async loadData() {
      this.loader = true;
      await GroupeApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Groupes = []; 
            for(const g of r.data){
              if(g.centre_idcentre == localStorage.getItem('centre'))
                this.Groupes.push(g)
            }
            var t = $("#groupes").DataTable();
            t.clear().rows.add(this.Groupes).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
      await AnneeApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Annees = r.data; 
          }
        }
      });
      await matiereApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Matieres = r.data; 
          }
        }
      });
      await profApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Profs = r.data; 
            console.log(this.Profs)
          }
        }
      });
      await documentationApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Documentations = r.data; 
          }
        }
      });
      await salleApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Salles = r.data; 
          }
        }
      });
      await cadenceApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Cadences = r.data; 
          }
        }
      });
      await categorieApi.getAllCategories((r) => {
        if(r!=null){
          if(r.success==true){
            this.Categories = r.data; 
          }
        }
      });
      await horaireApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Horaires = r.data; 
          }
        }
      });
      await AnneeApi.getAllPeriodes((r) => {
        if(r!=null){
          if(r.success==true){
            this.Periodes = r.data; 
          }
        }
      });
      CentreApi.getAll((r)=>{
        if(r!=null){
          this.Centres=r.data;
        }
      })
    },
    chooseProfA(){
      if(this.formA.ProfId && this.formA.MatiereId){
        this.formA.TarifId = null;
        profApi.getProfTarifsByIdProf({id:this.formA.ProfId},(r)=>{
          if(r!=null){
            this.Tarifs=[];
            for(const t of r.data){
              if(t.matiere_idmatiere == this.formA.MatiereId){
                this.Tarifs.push(t);
              }
            }
          }
        })
      }
    },
    chooseProfU(){
      if(this.formU.ProfId && this.formU.MatiereId){
        this.formU.TarifId = null;
        profApi.getProfTarifsByIdProf({id:this.formU.ProfId},(r)=>{
          if(r!=null){
            this.TarifsU=[];
            for(const t of r.data){
              if(t.matiere_idmatiere == this.formU.MatiereId){
                this.TarifsU.push(t);
              }
            }
          }
        })
      }
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#groupes").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          data: this.Groupes,
          columns: [
            { data: "idgroupe" },
            { data: "designation" },  
            { data: "mat" },  
            { data: "niveau" },  
            { data: "nbr-heures" },  
            { data: "nom" },  
            { data: "titre" },  
            { data: "salle" },  
            { data: "centre" },  
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowCategories"> Categories </button>',
            },
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowHoraires"> Horaires </button>',
            },
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowPeriodes"> Periodes </button>',
            },
            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info"> <i title="Modifier" style="cursor:pointer;" class="ti-pencil  btnUpdate" aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info btnDuplicate"> <i title="Modifier" style="cursor:pointer;" class="ti-layers" aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger"><i title="Supprimer" style="cursor:pointer;" class="ti-trash btnDelete" aria-hidden="true"></i></button></div>',
            },
          ],
        });
      });
      const thisVue = this;
      //for show Categories
      $("#groupes tbody ").on("click", ".btnShowCategories", function () {
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showCatsModal(data["idgroupe"]);
      });
      //for show Horaires
      $("#groupes tbody ").on("click", ".btnShowHoraires", function () {
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showHorairesModal(data["idgroupe"]);
      });
      //for show Periodes
      $("#groupes tbody ").on("click", ".btnShowPeriodes", function () {
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showPeriodesModal(data["idgroupe"]);
      });
      //for update
      $("#groupes tbody ").on("click", ".btnUpdate", function () {
       // $("#UpdateModal").modal();
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formU.idgroupe = data["idgroupe"];
        thisVue.formU.AnneeId = data["annee_idannee"]; 
        thisVue.formU.MatiereId = data["idmatiere"]; 
        thisVue.formU.Niveau = data["niveau"]; 
        thisVue.formU.CadenceId = data["idcadence-hebdo"]; 
        thisVue.formU.DocumentationId = data["iddocumentation"]; 
        thisVue.formU.ProfId = data["idprof"]; 
        thisVue.formU.SalleId = data["idsalle"]; 
        thisVue.formU.CentreId = data["centre_idcentre"]; 
        thisVue.formU.TarifId = data["prof_tarif"]; 
        profApi.getProfTarifsByIdProf({id:thisVue.formU.ProfId},(r)=>{
          if(r!=null){
            thisVue.TarifsU=[];
            for(const t of r.data){
              if(t.matiere_idmatiere == thisVue.formU.MatiereId){
                thisVue.TarifsU.push(t);
              }
            }
          }
        })
        thisVue.UpdateModal();
      });

      //for duplicate
      $("#groupes tbody ").on("click", ".btnDuplicate", async function () {
       // $("#UpdateModal").modal();
       
        thisVue.addModal();
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formA.AnneeId = data["annee_idannee"]; 
        thisVue.formA.MatiereId = data["idmatiere"]; 
        thisVue.formA.Niveau = data["niveau"]; 
        thisVue.formA.CadenceId = data["idcadence-hebdo"]; 
        thisVue.formA.DocumentationId = data["iddocumentation"]; 
        await GroupeApi.getGroupeCategoriesByIdGroupe({id:data["idgroupe"]},(r) => {
          if(r!=null){
            if(r.success==true){
              thisVue.ajouterCatsList = [];
              for(const d of r.data){
                thisVue.ajouterCatsList.push({
                  idcategorie:d.idcategorie,
                  designation:d.designation,
                })
              }
            }
          }
        });
        await GroupeApi.getGroupePeriodesByIdGroupe({id:data["idgroupe"]},(r) => {
          if(r!=null){
            if(r.success==true){
              thisVue.ajouterPeriodesList = [];
              for(const d of r.data){
                thisVue.ajouterPeriodesList.push({
                  idperiode:d.idperiode,
                  designation:d.designation,
                  nbr_heures:d['nbr-heures'],
                })
              }
            }
          }
        });
        await GroupeApi.getGroupeHorairesByIdGroupe({id:data["idgroupe"]},(r) => {
          if(r!=null){
            if(r.success==true){
              thisVue.ajouterHorairesList = [];
              for(const d of r.data){
                thisVue.ajouterHorairesList.push({
                  idhoraire:d.idhoraire,
                  designation:thisVue.weekDays[d['jour']] +' ' + d['heure-debut'] +':'+d['min-debut'] + ' - ' + d['heure-fin'] +':'+d['min-fin'],
                })
              }
            }
          }
        });
      });

      //for delete
      $("#groupes tbody ").on("click", ".btnDelete", function () {
        console.log("delete");
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#DeleteModal").modal();
        thisVue.DeleteModal()
        thisVue.formD.idgroupe = data["idgroupe"];
        thisVue.formD.AnneeId = data["annee_idannee"]; 
        thisVue.formD.MatiereId = data["idmatiere"]; 
        thisVue.formD.Niveau = data["niveau"]; 
        thisVue.formD.CadenceId = data["idcadence-hebdo"]; 
        thisVue.formD.DocumentationId = data["iddocumentation"]; 
        thisVue.formD.ProfId = data["idprof"]; 
        thisVue.formD.SalleId = data["idsalle"]; 
      });
    },
    //////////////////////////////////////////////
    async loadCatsData() {
      console.log(this.selectedId)
      await GroupeApi.getGroupeCategoriesByIdGroupe({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupeCategories = r.data; 
            console.log(r.data)
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showCatsModal(id) {
      this.selectedId = id;
      $("#showCatsModal").modal("show");
      this.loadCatsData();
    },
    closeModalshowCats() {
      $("#showCatsModal").modal("hide");
    },
    showDeleteCatsModal(d) {
      this.formCatsD.idcategorie = d['idcategorie'];
      $("#DeleteCatsModal").modal("show");
    },
    closeDeleteCatsModal() {
      $("#DeleteCatsModal").modal("hide");
    },
    showAddCatsModal() {
      $("#AddCatsModal").modal("show");
    },
    closeAddCatsModal() {
      $("#AddCatsModal").modal("hide");
    },
    saveCats(){ 
      if(this.v.formCatsA2.$invalid){//
        this.v.formCatsA2.$touch();//
      }//
      else{
      GroupeApi.createGroupeCategorie({
        categorie_idcategorie:this.formCatsA2.idcategorie,   
        groupe_idgroupe:this.selectedId, 
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadCatsData();
            this.closeAddCatsModal()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    DeleteCats(){
      GroupeApi.deleteGroupeCategorie({
        idcategorie:this.formCatsD.idcategorie, 
        idgroupe:this.selectedId,
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadCatsData();
            this.closeDeleteCatsModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    ///////////////////////////////////////////////////
    async loadHorairesData() {
      console.log(this.selectedId)
      await GroupeApi.getGroupeHorairesByIdGroupe({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupeHoraires = r.data; 
            console.log(r.data)
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showHorairesModal(id) {
      this.selectedId = id;
      $("#showHorairesModal").modal("show");
      this.loadHorairesData();
    },
    closeModalshowHoraires() {
      $("#showHorairesModal").modal("hide");
    },
    showDeleteHorairesModal(d) {
      this.formHorairesD.idhoraire = d['idhoraire'];
      $("#DeleteHorairesModal").modal("show");
    },
    closeDeleteHorairesModal() {
      $("#DeleteHorairesModal").modal("hide");
    },
    showAddHorairesModal() {
      $("#AddHorairesModal").modal("show");
    },
    closeAddHorairesModal() {
      $("#AddHorairesModal").modal("hide");
    },
    saveHoraires(){ 
      if(this.v.formHorairesA2.$invalid){//
        this.v.formHorairesA2.$touch();//
      }//
      else{
      GroupeApi.createGroupeHoraire({
        horaire_idhoraire:this.formHorairesA2.idhoraire,   
        groupe_idgroupe:this.selectedId, 
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadHorairesData();
            this.closeAddHorairesModal()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    DeleteHoraires(){
      GroupeApi.deleteGroupeHoraire({
        idhoraire:this.formHorairesD.idhoraire, 
        idgroupe:this.selectedId,
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadHorairesData();
            this.closeDeleteHorairesModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    ///////////////////////////////////////////////////
    async loadPeriodesData() {
      console.log(this.selectedId)
      await GroupeApi.getGroupePeriodesByIdGroupe({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupePeriodes = r.data; 
            console.log(r.data)
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showPeriodesModal(id) {
      this.selectedId = id;
      $("#showPeriodesModal").modal("show");
      this.loadPeriodesData();
    },
    closeModalshowPeriodes() {
      $("#showPeriodesModal").modal("hide");
    },
    showDeletePeriodesModal(d) {
      this.formPeriodesD.idperiode = d['idperiode'];
      $("#DeletePeriodesModal").modal("show");
    },
    closeDeletePeriodesModal() {
      $("#DeletePeriodesModal").modal("hide");
    },
    showAddPeriodesModal() {
      $("#AddPeriodesModal").modal("show");
    },
    closeAddPeriodesModal() {
      $("#AddPeriodesModal").modal("hide");
    },
    savePerdiodes(){ 
      if(this.v.formPeriodesA2.$invalid){//
        this.v.formPeriodesA2.$touch();//
      }//
      else{
      GroupeApi.createGroupePeriode({
        periode_idperiode:this.formPeriodesA2.idperiode,   
        nbr_heures:this.formPeriodesA2.nbr_heures,   
        groupe_idgroupe:this.selectedId, 
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadPeriodesData();
            this.closeAddPeriodesModal()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    DeletePeriodes(){
      GroupeApi.deleteGroupePeriode({
        idperiode:this.formPeriodesD.idperiode, 
        idgroupe:this.selectedId,
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadPeriodesData();
            this.closeDeletePeriodesModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    ///////////////////////////////////////////////////
    addModal() {
      this.formA.AnneeId=null;  
      this.formA.MatiereId=null;  
      this.formA.Niveau=''; 
      this.formA.CadenceId=null; 
      this.formA.DocumentationId=null; 
      this.formA.ProfId=null; 
      this.formA.SalleId=null; 
      this.formA.CentreId=null; 
      this.formA.TarifId=null; 
      this.ajouterHorairesList=[];
      this.ajouterCatsList=[];
      this.ajouterPeriodesList=[];
      $("#AddModal").modal("show");
    },
    closeModalAdd() {
      $("#AddModal").modal("hide");
    }, 
    UpdateModal() {
      $("#UpdateModal").modal("show");
    },
    closeModalUpdate() {
      $("#UpdateModal").modal("hide");
    },
    DeleteModal() {
      $("#DeleteModal").modal("show");
    },
    closeModalDelete() {
      $("#DeleteModal").modal("hide");
    },
    ClearModal(){},
    save(){ 
      if(this.v.formA.$invalid){//
        this.v.formA.$touch();//
      }//
      else{
      GroupeApi.add({
        annee_idannee:this.formA.AnneeId,
        matiere_idmatiere:this.formA.MatiereId, 
        niveau:this.formA.Niveau, 
        cadence_hebdo_idcadence_hebdo:this.formA.CadenceId, 
        documentation_iddocumentation:this.formA.DocumentationId, 
        prof_par_defaut:this.formA.ProfId, 
        salle_idsalle:this.formA.SalleId,  
        centre_idcentre:this.formA.CentreId,  
        prof_tarif:this.formA.TarifId,  
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
             let i = 0;
             let j = 0;
             let k = 0;
             let v1 = false;
             let v2 = false;
             let v3 = false;
             for(const m of this.ajouterCatsList){
               i++;
               GroupeApi.createGroupeCategorie({
                  categorie_idcategorie:m.idcategorie,   
                  groupe_idgroupe:r.id, 
              },(r) => {
                console.log(r)
                if(r!=null)
                {
                  if(r.success==true){
                    if(i==this.ajouterCatsList.length){
                      v1 = true;
                    }
                    if(v1 && v2 && v3){
                      this.loadData();
                      this.ClearModal();
                      this.closeModalAdd()
                    }
                  }
                  else{
                    console.log("error",r.msg)
                  }
                }
              });
             }
             for(const m of this.ajouterHorairesList){
               j++;
               GroupeApi.createGroupeHoraire({
                  horaire_idhoraire:m.idhoraire,   
                  groupe_idgroupe:r.id, 
              },(r) => {
                console.log(r)
                if(r!=null)
                {
                  if(r.success==true){
                    if(j==this.ajouterHorairesList.length){
                      v2 = true;
                    }
                    if(v1 && v2 && v3){
                      this.loadData();
                      this.ClearModal();
                      this.closeModalAdd()
                    }
                  }
                  else{
                    console.log("error",r.msg)
                  }
                }
              });
             }
             for(const m of this.ajouterPeriodesList){
               k++;
               GroupeApi.createGroupePeriode({
                  periode_idperiode:m.idperiode,   
                  nbr_heures:m.nbr_heures,   
                  groupe_idgroupe:r.id, 
              },(r) => {
                console.log(r)
                if(r!=null)
                {
                  if(r.success==true){
                    if(k==this.ajouterPeriodesList.length){
                      v3 = true;
                    }
                    if(v1 && v2 && v3){
                      this.loadData();
                      this.ClearModal();
                      this.closeModalAdd()
                    }
                  }
                  else{
                    console.log("error",r.msg)
                  }
                }
              });
             }
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Update(){
      if(this.v.formU.$invalid){//
        this.v.formU.$touch();//
      }//
      else{
      GroupeApi.update({
        id:this.formU.idgroupe, 
        annee_idannee:this.formU.AnneeId,
        matiere_idmatiere:this.formU.MatiereId, 
        niveau:this.formU.Niveau, 
        cadence_hebdo_idcadence_hebdo:this.formU.CadenceId, 
        documentation_iddocumentation:this.formU.DocumentationId, 
        prof_par_defaut:this.formU.ProfId, 
        salle_idsalle:this.formU.SalleId,    
        centre_idcentre:this.formU.CentreId,  
        prof_tarif:this.formU.TarifId,  
       },(r) => { 
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalUpdate()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Delete(){
      GroupeApi.delete({
        id:this.formD.idgroupe, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalDelete()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
