<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Cadences</h4>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15">
                  <div class="clearfix">
                    <button
                      type="button"
                      class="waves-effect waves-light btn mb-5 bg-gradient-warning"
                      @click="addModal()"
                    >
                      + Ajouter
                    </button>
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="cadences"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>nbr</th> 
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->

        <!-- modal Ajouter cadence -->
        <div
          class="modal fade"
          id="AddModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter cadence</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">nbr</label>
                              <div :class="v.formA.nbr.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="nbr..."
                                  class="form-control"
                                  v-model="formA.nbr"
                                  @input="v.formA.nbr.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.nbr.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalAdd"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcadence" @click="save">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Ajouter cadence -->

        <!-- modal Modifier cadence -->
        <div
          class="modal fade"
          id="UpdateModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">modifier cadence</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body">
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">nbr</label>
                              <div :class="v.formU.nbr.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="nbr..."
                                  class="form-control"
                                  v-model="formU.nbr"
                                  @input="v.formU.nbr.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.nbr.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalUpdate"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcadence" @click="Update">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier cadence -->

        <!-- modal Supprimer cadence -->
        <div
          class="modal fade"
          id="DeleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer cadence</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalDelete"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce cadence?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <label class="form-label">nbr</label>
                    <input
                      type="text" 
                      class="form-control"
                      v-model="formD.nbr"
                    />
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalDelete">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deletecadence" @click="Delete">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer cadence -->
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import CadenceApi from "../../../api/cadence";

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
  },
  data() {
    return { 
      loader:false,
      Cadences:[],  
      formA:{
        nbr:'',
      },
      formU:{
        id:'',
        nbr:'', 
      },
      formD:{ 
        id:'',
        nbr:'', 
      },
      url:null,
    };
  },
  validations () {
    return { 
      formA:{
        nbr:{ required },
      },
      formU:{ 
        nbr:{ required }, 
      },
    }
  },
  mounted() {
    this.datatable();
    console.log("kf") 
  },
  methods: {
    async loadData() {
      this.loader = true;
      await CadenceApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Cadences = r.data; 
            console.log(r.data)
            var t = $("#cadences").DataTable();
            t.clear().rows.add(this.Cadences).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#cadences").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          data: this.Cadences,
          columns: [
            { data: "idcadence-hebdo" },
            { data: "nbr-heures" }, 

            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info"> <i title="Modifier" style="cursor:pointer;" class="ti-pencil  btnUpdate" aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger"><i title="Supprimer" style="cursor:pointer;" class="ti-trash btnDelete" aria-hidden="true"></i></button></div>',
            },
          ],
        });
      });
      const thisVue = this;
      //for update
      $("#cadences tbody ").on("click", ".btnUpdate", function () {
        console.log("update");
       // $("#UpdateModal").modal();
       thisVue.UpdateModal()
        var t = $("#cadences").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formU.id = data["idcadence-hebdo"];
        thisVue.formU.nbr = data["nbr-heures"]; 
      });

      //for delete
      $("#cadences tbody ").on("click", ".btnDelete", function () {
        console.log("delete");
        var t = $("#cadences").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#DeleteModal").modal();
        thisVue.DeleteModal()
        thisVue.formD.id = data["idcadence-hebdo"];
        thisVue.formD.nbr = data["nbr-heures"]; 
      });
    },
    addModal() {
      $("#AddModal").modal("show");
    },
    closeModalAdd() {
      $("#AddModal").modal("hide");
    }, 
    UpdateModal() {
      $("#UpdateModal").modal("show");
    },
    closeModalUpdate() {
      $("#UpdateModal").modal("hide");
    },
    DeleteModal() {
      $("#DeleteModal").modal("show");
    },
    closeModalDelete() {
      $("#DeleteModal").modal("hide");
    },
    ClearModal(){},
    save(){ 
      if(this.v.formA.$invalid){//
        this.v.formA.$touch();//
      }//
      else{
      CadenceApi.add({
        nbr:this.formA.nbr,   
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalAdd()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Update(){
      if(this.v.formU.$invalid){//
        this.v.formU.$touch();//
      }//
      else{
      CadenceApi.update({
        id:this.formU.id, 
        nbr:this.formU.nbr,   
       },(r) => { 
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalUpdate()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Delete(){
      CadenceApi.delete({
        id:this.formD.id, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalDelete()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
