import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
export default {
    getAll(callback){

    axios.get(myConfig.api_url+`/api/v1/allAssocies?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    add(args,callback){

        //nom:?, telephone:?, email:?,
        axios.post(myConfig.api_url+`/api/v1/addAssocie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    update(args,callback){
        //id:?, nom:?, telephone:?, email:?,
        axios.post(myConfig.api_url+`/api/v1/updateAssocie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    delete(args,callback){
        //id:? ,
        axios.post(myConfig.api_url+`/api/v1/deleteAssocie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}