<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Profs</h4>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15">
                  <div class="clearfix">
                    <button
                      type="button"
                      class="waves-effect waves-light btn mb-5 bg-gradient-warning"
                      @click="addModal()"
                    >
                      + Ajouter
                    </button>
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="profs"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>nom</th> 
                            <th>prenom</th> 
                            <th>telephone</th> 
                            <th>username</th> 
                            <th>Mot de passe</th> 
                            <th>center</th>
                            <th>centerID</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->

        <!-- modal Ajouter prof -->
        <div
          class="modal fade"
          id="AddModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter prof</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">nom</label>
                              <div :class="v.formA.nom.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="nom..."
                                  class="form-control"
                                  v-model="formA.nom"
                                  @input="v.formA.nom.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.nom.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">prenom</label>
                              <div :class="v.formA.prenom.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="prenom..."
                                  class="form-control"
                                  v-model="formA.prenom"
                                  @input="v.formA.prenom.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.prenom.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">telephone</label>
                              <div :class="v.formA.telephone.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="telephone..."
                                  class="form-control"
                                  v-model="formA.telephone"
                                  @input="v.formA.telephone.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.telephone.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">username</label>
                              <div :class="v.formA.email.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="email..."
                                  class="form-control"
                                  v-model="formA.email"
                                  @input="v.formA.email.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.email.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">mot de passe</label>
                              <div :class="v.formA.mot_de_passe.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="mot de passe..."
                                  class="form-control"
                                  v-model="formA.mot_de_passe"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.mot_de_passe.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Center</label>
                              <div :class="v.formA.centerId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.centerId">
                                  <option :value="null" disabled>Choisie Center</option> 
                                  <option
                                  :value="Centre.id"
                                   v-for="Centre in Centres"
                                   :key="Centre.id"
                                   >{{Centre.nom}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.centerId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                        <hr>
                        <!--//////////////////////////////////////////////////////////////-->
                        <div>
                          <h4 class="form-label">Matiere - Tarif :</h4>
                          <div class="row">
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">Matiere</label>
                              <div :class="v.formMatsA.mat.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formMatsA.mat">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idmatiere']+'|'+m['designation']"
                                    v-for="m in Matieres"
                                    :key="m['idmatiere']"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formMatsA.mat.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">tarif</label>
                              <div :class="v.formMatsA.tarif.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <input
                                  type="number"
                                  min="0"
                                  placeholder="Tarif..."
                                  class="form-control"
                                  v-model="formMatsA.tarif"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formMatsA.tarif.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-2">
                            <br>
                            <button type="button" class="btn btn-primary float-end" @click="addMatsJson">
                              Ajouter
                            </button>
                          </div>
                          </div>
                          <div style="overflow-x: auto;max-height: 500px;">
                            <table
                              class="table table-bordered table-striped dataTable"
                            >
                              <thead>
                                <tr>
                                  <th>Matiere</th>
                                  <th>Tarif</th> 
                                </tr>
                              </thead>
                              <tbody>
                                  <tr v-if="ajouterMatsList.length==0"><td colspan="2" align="center">Ajouter Matiere - tarif</td></tr>
                                  <tr v-for="d,i of ajouterMatsList" :key="i">
                                    <td>{{d.designation}}</td>
                                    <td>{{d.montant}}</td>
                                    <td>
                                      <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="deleteMatsJson(i)">
                                        <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                                      </button>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!--//////////////////////////////////////////////////////////////-->
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalAdd"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addprof" @click="save">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Ajouter prof -->

        <!-- modal Modifier prof -->
        <div
          class="modal fade"
          id="UpdateModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">modifier prof</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body">
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">nom</label>
                              <div :class="v.formU.nom.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="nom..."
                                  class="form-control"
                                  v-model="formU.nom"
                                  @input="v.formU.nom.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.nom.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">prenom</label>
                              <div :class="v.formU.prenom.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="prenom..."
                                  class="form-control"
                                  v-model="formU.prenom"
                                  @input="v.formU.prenom.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.prenom.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">telephone</label>
                              <div :class="v.formU.telephone.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="telephone..."
                                  class="form-control"
                                  v-model="formU.telephone"
                                  @input="v.formU.telephone.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.telephone.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">username</label>
                              <div :class="v.formU.email.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="email..."
                                  class="form-control"
                                  v-model="formU.email"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.email.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">mot de passe</label>
                              <div :class="v.formU.mot_de_passe.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="mot de passe..."
                                  class="form-control"
                                  v-model="formU.mot_de_passe"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.mot_de_passe.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Center</label>
                              <div :class="v.formU.centerId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.centerId">
                                  <option
                                  :value="Centre.id"
                                   v-for="Centre in Centres"
                                   :key="Centre.id"
                                   >{{Centre.nom}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.centerId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          </div> 
                          <button @click="showMatsModal(formU.idprof)" class="waves-effect btn btn-info"> Matiers-Tarif </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalUpdate"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addprof" @click="Update">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier prof -->

        <!-- modal Supprimer prof -->
        <div
          class="modal fade"
          id="DeleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer prof</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalDelete"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce prof?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <label class="form-label">nom</label>
                    <input
                      type="text" 
                      class="form-control"
                      disabled
                      v-model="formD.nom"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div>
                    <label class="form-label">prenom</label>
                    <input
                      type="text" 
                      class="form-control"
                      disabled
                      v-model="formD.prenom"
                    />
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalDelete">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deleteprof" @click="Delete">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer prof -->
        

        <!--//////////////////////////////////////////////////////////////-->
        <!-- modal mqtiers-tqrif prof -->
        <div
          class="modal fade"
          id="showMatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des matieres et tarifs</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowMats"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>Matiere</th>
                        <th>Tarif</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="ProfsMats.length==0"><td colspan="2" align="center">Aucun matiere trouve</td></tr>
                        <tr v-for="d of ProfsMats" :key="d['idtarif-prof-matiere']">
                          <td>{{d.designation}}</td>
                          <td>{{d.montant}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info" @click="showUpdateMatsModal(d)">
                              <i title="Modifier" style="cursor:pointer;" class="ti-pencil" aria-hidden="true"></i>
                            </button> 
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="showDeleteMatsModal(d)">
                              <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowMats">
                  fermer
                </button>
                <button @click="showAddMatsModal()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal matiers-tarif prof -->

        <!-- modal Supprimer matiers-tarif prof -->
        <div
          class="modal fade"
          id="DeleteMatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer matiere-tarif</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeleteMatsModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce matiere-tarif?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <strong class="form-label">Matiere : </strong>
                    {{formMatsD.mat}}
                  </div>
                </div>
                <div class="col-12">
                  <div>
                    <strong class="form-label">tarif : </strong>
                    {{formMatsD.tarif}}
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeleteMatsModal">
                  Non
                </button>
                <button @click="DeleteProfTarifs()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer matiers-tarif prof -->

        <!-- modal update matiers-tarif prof -->
        <div
          class="modal fade"
          id="UpdateMatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modifier matiere-tarif</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeUpdateMatsModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment modifier ce matiere-tarif?</h4>
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Matiere</label>
                    <div :class="v.formMatsU.mat.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      <select class="form-select" v-model="formMatsU.mat">
                        <option :value="null" disabled>Choisie </option> 
                        <option
                        :value="m['idmatiere']"
                          v-for="m in Matieres"
                          :key="m['idmatiere']"
                          >{{m.designation}}</option> 
                      </select>
                    </div>
                    <div class="input-errors" v-for="error of v.formMatsU.mat.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">tarif</label>
                    <div :class="v.formMatsU.tarif.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      
                      <input
                        type="number"
                        min="0"
                        placeholder="Tarif..."
                        class="form-control"
                        v-model="formMatsU.tarif"
                        @input="v.formMatsU.tarif.$touch()"
                      />
                    </div>
                    <div class="input-errors" v-for="error of v.formMatsU.tarif.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeUpdateMatsModal">
                  Non
                </button>
                <button @click="UpdateProfTarifs()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal update matiers-tarif prof -->

        <!-- modal add matiers-tarif prof -->
        <div
          class="modal fade"
          id="AddMatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter matiere-tarif</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAddMatsModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Matiere</label>
                    <div :class="v.formMatsA2.mat.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      <select class="form-select" v-model="formMatsA2.mat">
                        <option :value="null" disabled>Choisie </option> 
                        <option
                        :value="m['idmatiere']"
                          v-for="m in Matieres"
                          :key="m['idmatiere']"
                          >{{m.designation}}</option> 
                      </select>
                    </div>
                    <div class="input-errors" v-for="error of v.formMatsA2.mat.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">tarif</label>
                    <div :class="v.formMatsA2.tarif.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      
                      <input
                        type="number"
                        min="0"
                        placeholder="Tarif..."
                        class="form-control"
                        v-model="formMatsA2.tarif"
                        @input="v.formMatsA2.tarif.$touch()"
                      />
                    </div>
                    <div class="input-errors" v-for="error of v.formMatsA2.tarif.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAddMatsModal">
                  Non
                </button>
                <button @click="saveProfTarifs()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal add matiers-tarif prof -->
        <!--//////////////////////////////////////////////////////////////-->
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import ProfApi from "../../../api/prof";
import centerApi from "../../../api/centre";
import MatiereApi from "../../../api/matiere";

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
  },
  data() {
    return { 
      loader:false,
      selectedId:null,
      Profs:[],  
      ProfsMats:[],
      Matieres:[],
      Centres:[],
      ajouterMatsList:[],
      formA:{
        nom:'',
        prenom:'',
        telephone:'',
        email:'',
        mot_de_passe:'',
        centerId:null
      },
      formU:{
        idprof:'',
        nom:'',
        prenom:'',
        telephone:'',
        email:'',
        mot_de_passe:'',
        centerId:null
      },
      formD:{ 
        idprof:'',
        nom:'',
        prenom:'',
      },
      formMatsA:{
        mat:null,
        tarif:'',
      },
      formMatsA2:{
        mat:null,
        tarif:'',
      },
      formMatsU:{
        id:'',
        mat:'',
        tarif:'',
      },
      formMatsD:{ 
        id:'',
        mat:'',
        tarif:'',
      },
      url:null,
    };
  },
  validations () {
    return { 
      formA:{
        nom:{ required },
        prenom:{ required },
        telephone:{ required },
        email:{ required },
        mot_de_passe:{ required },
        centerId:{ required },
      },
      formU:{ 
        nom:{ required },
        prenom:{ required },
        telephone:{ required },
        email:{ required },
        mot_de_passe:{ required },
        centerId:{ required },
      },
      formMatsA:{ 
        mat:{ required },
        tarif:{ required },
      },
      formMatsA2:{ 
        mat:{ required },
        tarif:{ required },
      },
      formMatsU:{ 
        mat:{ required },
        tarif:{ required },
      },
    }
  },
  mounted() {
    this.datatable();
    console.log("kf")
    centerApi.getAll((r) => {
      this.Centres=r.data; 
      console.log("hh",r.data)
    }); 
  },
  methods: {
    addMatsJson(){
      if(this.v.formMatsA.$invalid){
        this.v.formMatsA.$touch();
      }
      else{
        this.ajouterMatsList.push({
          idmatiere:this.formMatsA.mat.split('|')[0],
          designation:this.formMatsA.mat.split('|')[1],
          montant:this.formMatsA.tarif,
        })
        this.formMatsA={
          mat:null,
          tarif:'',
        };
      }
    },
    deleteMatsJson(i){
      this.ajouterMatsList.splice(i,1);
    },
    async loadData() {
      this.loader = true;
      await ProfApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Profs = []; 
            for(const p of r.data){
              if(p.centre_idcentre == localStorage.getItem('centre'))
                this.Profs.push(p)
            }
            var t = $("#profs").DataTable();
            t.clear().rows.add(this.Profs).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
      await MatiereApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Matieres = r.data; 
          }
        }
      });
    },
    async loadMatsData() {
      console.log(this.selectedId)
      await ProfApi.getProfTarifsByIdProf({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.ProfsMats = r.data; 
            console.log(r.data)
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#profs").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          data: this.Profs,
          columns: [
            { data: "idprof" },
            { data: "nom" }, 
            { data: "prenom" }, 
            { data: "telephone" },  
            { data: "email" }, 
            { data: "mot-de-passe" }, 
            { data: "center" },  
            { data: "centre_idcentre",visible:false },  
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowMats"> Matiers-Tarif </button>',
            },
            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info  btnUpdate"> <i title="Modifier" style="cursor:pointer;" class="ti-pencil" aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger btnDelete"><i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i></button></div>',
            },
          ],
        });
      });
      const thisVue = this;
      //for show matiers-tarif
      $("#profs tbody ").on("click", ".btnShowMats", function () {
        var t = $("#profs").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showMatsModal(data["idprof"]);
      });

      //for update
      $("#profs tbody ").on("click", ".btnUpdate", function () {
        console.log("update");
       // $("#UpdateModal").modal();
       thisVue.UpdateModal()
        var t = $("#profs").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formU.idprof = data["idprof"];
        thisVue.formU.nom = data["nom"]; 
        thisVue.formU.prenom = data["prenom"]; 
        thisVue.formU.telephone = data["telephone"]; 
        thisVue.formU.email = data["email"]; 
        thisVue.formU.mot_de_passe = data["mot-de-passe"]; 
        thisVue.formU.centerId =data["centre_idcentre"]; 
      });

      //for delete
      $("#profs tbody ").on("click", ".btnDelete", function () {
        console.log("delete");
        var t = $("#profs").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#DeleteModal").modal();
        thisVue.DeleteModal()
        thisVue.formD.idprof = data["idprof"];
        thisVue.formD.nom = data["nom"]; 
        thisVue.formD.prenom = data["prenom"]; 
      });
    },
    addModal() {
      $("#AddModal").modal("show");
    },
    closeModalAdd() {
      $("#AddModal").modal("hide");
    }, 
    UpdateModal() {
      $("#UpdateModal").modal("show");
    },
    closeModalUpdate() {
      $("#UpdateModal").modal("hide");
    },
    DeleteModal() {
      $("#DeleteModal").modal("show");
    },
    closeModalDelete() {
      $("#DeleteModal").modal("hide");
    },
    showMatsModal(id) {
      this.selectedId = id;
      $("#showMatsModal").modal("show");
      this.loadMatsData();
    },
    closeModalshowMats() {
      $("#showMatsModal").modal("hide");
    },
    showDeleteMatsModal(d) {
      this.formMatsD.id = d['idtarif-prof-matiere'];
      this.formMatsD.mat = d.designation;
      this.formMatsD.tarif = d.montant;
      $("#DeleteMatsModal").modal("show");
    },
    closeDeleteMatsModal() {
      $("#DeleteMatsModal").modal("hide");
    },
    showUpdateMatsModal(d) {
      this.formMatsU.id = d['idtarif-prof-matiere'];
      this.formMatsU.mat = d.idmatiere;
      this.formMatsU.tarif = d.montant;
      $("#UpdateMatsModal").modal("show");
    },
    closeUpdateMatsModal() {
      $("#UpdateMatsModal").modal("hide");
    },
    showAddMatsModal() {
      $("#AddMatsModal").modal("show");
    },
    closeAddMatsModal() {
      $("#AddMatsModal").modal("hide");
    },
    ClearModal(){},
    saveProfTarifs(){ 
      if(this.v.formMatsA2.$invalid){//
        this.v.formMatsA2.$touch();//
      }//
      else{
      ProfApi.addProfTarifs({
        montant:this.formMatsA2.tarif,   
        prof_idprof:this.selectedId,   
        matiere_idmatiere:this.formMatsA2.mat, 
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadMatsData();
            this.closeAddMatsModal()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    UpdateProfTarifs(){
      if(this.v.formMatsU.$invalid){//
        this.v.formMatsU.$touch();//
      }//
      else{
      ProfApi.updateProfTarifs({
        id:this.formMatsU.id,   
        montant:this.formMatsU.tarif,   
        prof_idprof:this.selectedId,   
        matiere_idmatiere:this.formMatsU.mat, 
       },(r) => { 
         if(r!=null)
         {
           if(r.success==true){
            this.loadMatsData();
            this.closeUpdateMatsModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    DeleteProfTarifs(){
      ProfApi.deleteProfTarifs({
        id:this.formMatsD.id, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadMatsData();
            this.closeDeleteMatsModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    save(){ 
      if(this.v.formA.$invalid){//
        this.v.formA.$touch();//
      }//
      else{
      $("#globalLoader").removeClass("H");
      $("#globalLoader").addClass("S");
      ProfApi.add({
        nom:this.formA.nom,   
        prenom:this.formA.prenom,   
        telephone:this.formA.telephone,   
        email:this.formA.email,   
        mot_de_passe:this.formA.mot_de_passe,   
        centre_idcentre:this.formA.centerId, 
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
             let i = 0;
             if(this.ajouterMatsList.length == 0){
                this.loadData();
                this.ClearModal();
                this.closeModalAdd();
                $("#globalLoader").removeClass("S");
                $("#globalLoader").addClass("H");
             }
             for(const m of this.ajouterMatsList){
               i++;
               ProfApi.addProfTarifs({
                montant:m.montant,   
                prof_idprof:r.id,   
                matiere_idmatiere:m.idmatiere, 
              },(r) => {
                console.log(r)
                if(r!=null)
                {
                  if(r.success==true){
                    if(i==this.ajouterMatsList.length){
                      this.loadData();
                      this.ClearModal();
                      this.closeModalAdd();
                      $("#globalLoader").removeClass("S");
                      $("#globalLoader").addClass("H");
                    }
                  }
                  else{
                    console.log("error",r.msg)
                  }
                }
              });
             }
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Update(){
      if(this.v.formU.$invalid){//
        this.v.formU.$touch();//
      }//
      else{
      ProfApi.update({
        id:this.formU.idprof, 
        nom:this.formU.nom,   
        prenom:this.formU.prenom,   
        telephone:this.formU.telephone,   
        email:this.formU.email,     
        mot_de_passe:this.formU.mot_de_passe,
        centre_idcentre:this.formU.centerId, 
       },(r) => { 
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalUpdate()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Delete(){
      ProfApi.delete({
        id:this.formD.idprof, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalDelete()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
