<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Conventions</h4>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15">
                  <div class="clearfix">
                    <button
                      type="button"
                      class="waves-effect waves-light btn mb-5 bg-gradient-warning"
                      @click="addModal()"
                    >
                      + Ajouter
                    </button>
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="conventions"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>ORGANISME</th> 
                            <th>Annee</th> 
                            <th>Active</th> 
                            <th>Par defaut </th> 
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->

        <!-- modal Ajouter convention -->
        <div
          class="modal fade"
          id="AddModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter convention</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">ORGANISME</label>
                              <div :class="v.formA.organisme.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="organisme..."
                                  class="form-control"
                                  v-model="formA.organisme"
                                  @input="v.formA.organisme.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.organisme.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Annee</label>
                              <div :class="v.formA.annee.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.annee">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idannee']"
                                    v-for="m in annees"
                                    :key="m['idannee']"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.annee.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Centre :</label>
                              <div class="input-group mb-15">
                                <select class="form-select" v-model="formA.CentreId">
                                  <option :value="null" disabled>Tous </option> 
                                  <option
                                  :value="m['id']"
                                    v-for="m in Centres"
                                    :key="m['id']"
                                    >{{m.nom}}</option> 
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div>
                                <span
                                  ><input
                                    type="checkbox"
                                    style="margin-right: 5px; position: unset; opacity: 1"
                                    value="0" 
                                    v-model="formA.active"
                                  />Active</span
                                >
                              </div>
                             </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div>
                                <span
                                  ><input
                                    type="checkbox"
                                    style="margin-right: 5px; position: unset; opacity: 1"
                                    value="0" 
                                    v-model="formA.default"
                                  />par defaut</span
                                >
                              </div>
                             </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalAdd"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addconvention" @click="save">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Ajouter convention -->

        <!-- modal Modifier convention -->
        <div
          class="modal fade"
          id="UpdateModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">modifier convention</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body">
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">ORGANISME</label>
                              <div :class="v.formU.organisme.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="organisme..."
                                  class="form-control"
                                  v-model="formU.organisme"
                                  @input="v.formU.organisme.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.organisme.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Annee</label>
                              <div :class="v.formU.annee.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.annee">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idannee']"
                                    v-for="m in annees"
                                    :key="m['idannee']"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.annee.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Centre :</label>
                              <div class="input-group mb-15">
                                <select class="form-select" v-model="formU.CentreId">
                                  <option :value="null" disabled>Tous </option> 
                                  <option
                                  :value="m['id']"
                                    v-for="m in Centres"
                                    :key="m['id']"
                                    >{{m.nom}}</option> 
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div>
                                <span>
                                  <input
                                    type="checkbox"
                                    style="margin-right: 5px; position: unset; opacity: 1"
                                    value="0"
                                    v-model="formU.active"
                                  />
                                  Active
                                </span>
                              </div>
                             </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div>
                                <span
                                  ><input
                                    type="checkbox"
                                    style="margin-right: 5px; position: unset; opacity: 1"
                                    value="0" 
                                    v-model="formU.default"
                                  />par defaut</span
                                >
                              </div>
                             </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalUpdate"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addconvention" @click="Update">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier convention -->

        <!-- modal Supprimer convention -->
        <div
          class="modal fade"
          id="DeleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer convention</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalDelete"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce convention?</h4>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalDelete">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deleteconvention" @click="Delete">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer convention -->
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import ConventionApi from "../../../api/convention";
import AnneeApi from "../../../api/annees";
import CentreApi from "../../../api/centre";

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
  },
  data() {
    return { 
      loader:false,
      Conventions:[],  
      annees:[],
      Centres:[],
      formA:{
        organisme:'',
        annee:null,
        active :false,
        default:false,
        CentreId:null,
      },
      formU:{
        idconvention:'',
        organisme:'',
        annee:null,
        active :false,
        default:false,
        CentreId:null,
      },
      formD:{ 
        idconvention:'',
      },
      url:null,
    };
  },
  validations () {
    return { 
      formA:{
        organisme:{ required },
        annee:{ required },
        active:{ required },
        default:{ required },
      },
      formU:{ 
        organisme:{ required },
        annee:{ required },
        active:{ required },
        default:{ required },
      },
    }
  },
  mounted() {
    this.datatable();
    CentreApi.getAll((r)=>{
      if(r!=null){
        this.Centres=r.data;
      }
    })
  },
  methods: {
    async loadData() {
      this.loader = true;
      await ConventionApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Conventions = r.data; 
            console.log(r.data)
            var t = $("#conventions").DataTable();
            t.clear().rows.add(this.Conventions).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
      await AnneeApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.annees = r.data; 
          }
        }
      });
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#conventions").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          data: this.Conventions,
          columns: [
            { data: "idconvention" },
            { data: "organisme" }, 
            { data: "designation" }, 
            { data: "active" }, 
            { data: "par-defaut" }, 

            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info"> <i title="Modifier" style="cursor:pointer;" class="ti-pencil  btnUpdate" aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger"><i title="Supprimer" style="cursor:pointer;" class="ti-trash btnDelete" aria-hidden="true"></i></button></div>',
            },
          ],
        });
      });
      const thisVue = this;
      //for update
      $("#conventions tbody ").on("click", ".btnUpdate", function () {
        console.log("update");
       // $("#UpdateModal").modal();
       thisVue.UpdateModal()
        var t = $("#conventions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formU.idconvention = data["idconvention"];
        thisVue.formU.organisme = data["organisme"];
        thisVue.formU.annee = data["idannee"];
        thisVue.formU.CentreId = data["centre"];
        thisVue.formU.active = data["active"]==1?true:false;
        thisVue.formU.default = data["par-defaut"]==1?true:false;
      });

      //for delete
      $("#conventions tbody ").on("click", ".btnDelete", function () {
        console.log("delete");
        var t = $("#conventions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#DeleteModal").modal();
        thisVue.DeleteModal()
        thisVue.formD.idconvention = data["idconvention"];
      });
    },
    addModal() {
      $("#AddModal").modal("show");
    },
    closeModalAdd() {
      $("#AddModal").modal("hide");
    }, 
    UpdateModal() {
      $("#UpdateModal").modal("show");
    },
    closeModalUpdate() {
      $("#UpdateModal").modal("hide");
    },
    DeleteModal() {
      $("#DeleteModal").modal("show");
    },
    closeModalDelete() {
      $("#DeleteModal").modal("hide");
    },
    ClearModal(){},
    save(){ 
      if(this.v.formA.$invalid){//
        this.v.formA.$touch();//
      }//
      else{
      ConventionApi.add({
        annee_idannee:this.formA.annee,   
        organisme:this.formA.organisme,   
        active:this.formA.active,   
        par_defaut:this.formA.default,      
        centre:this.formA.CentreId,      
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalAdd()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Update(){
      if(this.v.formU.$invalid){//
        this.v.formU.$touch();//
      }//
      else{
      ConventionApi.update({
        id:this.formU.idconvention, 
        annee_idannee:this.formU.annee,   
        organisme:this.formU.organisme,   
        active:this.formU.active==true?1:0,   
        par_defaut:this.formU.default==true?1:0,     
        centre:this.formU.CentreId,
       },(r) => { 
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalUpdate()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Delete(){
      ConventionApi.delete({
        id:this.formD.idconvention, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalDelete()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
