<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Formules</h4>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15">
                  <div class="clearfix">
                    <button
                      type="button"
                      class="waves-effect waves-light btn mb-5 bg-gradient-warning"
                      @click="addModal()"
                    >
                      + Ajouter
                    </button>
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="formules"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Annee</th> 
                            <th>Orgamisme</th> 
                            <th>Cadence</th> 
                            <th>Frais d'inscription</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->

        <!-- modal Ajouter formule -->
        <div
          class="modal fade"
          id="AddModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter formule</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row">

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Convention :</label>
                              <div :class="v.formA.ConventionId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.ConventionId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idconvention']"
                                    v-for="m in Conventions"
                                    :key="m['idconvention']"
                                    >{{m.organisme}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.ConventionId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Cadence hebdomodaire :</label>
                              <div :class="v.formA.cadencehebdoId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.cadencehebdoId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idcadence-hebdo']"
                                    v-for="m in Cadences"
                                    :key="m['idcadence-hebdo']"
                                    >{{m['nbr-heures'] + " H/Semaine"}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.cadencehebdoId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Tarif Frais d'inscription :</label>
                              <div :class="v.formA.tarif.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                 <input
                                  type="text"
                                  placeholder="Frais d'inscription..."
                                  class="form-control"
                                  v-model="formA.tarif"
                                  @input="v.formA.tarif.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.tarif.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <hr>
                        <!--//////////////////////////////////////////////////////////////-->
                        <div>
                          <h4 class="form-label">Categorie :</h4>
                          <div class="row">
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">Categorie</label>
                              <div :class="v.formCatsA.cat.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formCatsA.cat">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idcategorie']+'|'+m['designation']"
                                    v-for="m in Categories"
                                    :key="m['idcategorie']"
                                    :style="ajouterCatsList.map(v=>v.idcategorie).indexOf(m['idcategorie'].toString())!=-1?'display:none':''"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formCatsA.cat.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-2">
                            <br>
                            <button type="button" class="btn btn-primary float-end" @click="addCatsJson">
                              Ajouter
                            </button>
                          </div>
                          </div>
                          <div style="overflow-x: auto;max-height: 500px;">
                            <table
                              class="table table-bordered table-striped dataTable"
                            >
                              <thead>
                                <tr>
                                  <th>Categorie</th>
                                </tr>
                              </thead>
                              <tbody>
                                  <tr v-if="ajouterCatsList.length==0"><td colspan="2" align="center">Ajouter Categorie</td></tr>
                                  <tr v-for="d,i of ajouterCatsList" :key="i">
                                    <td>{{d.designation}}</td>
                                    <td>
                                      <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="deleteCatsJson(i)">
                                        <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                                      </button>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!--//////////////////////////////////////////////////////////////-->
                        <hr>
                        <!--//////////////////////////////////////////////////////////////-->
                        <div>
                          <h4 class="form-label">Matiere :</h4>
                          <div class="row">
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">Matiere</label>
                              <div :class="v.formMatsA.val.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formMatsA.val">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idmatiere']+'|'+m['designation']"
                                    v-for="m in Matieres"
                                    :key="m['idmatiere']"
                                    :style="ajouterMatsList.map(v=>v.idmatiere).indexOf(m['idmatiere'].toString())!=-1?'display:none':''"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formMatsA.val.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-2">
                            <br>
                            <button type="button" class="btn btn-primary float-end" @click="addMatsJson">
                              Ajouter
                            </button>
                          </div>
                          </div>
                          <div style="overflow-x: auto;max-height: 500px;">
                            <table
                              class="table table-bordered table-striped dataTable"
                            >
                              <thead>
                                <tr>
                                  <th>Categorie</th>
                                </tr>
                              </thead>
                              <tbody>
                                  <tr v-if="ajouterMatsList.length==0"><td colspan="2" align="center">Ajouter Categorie</td></tr>
                                  <tr v-for="d,i of ajouterMatsList" :key="i">
                                    <td>{{d.designation}}</td>
                                    <td>
                                      <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="deleteMatsJson(i)">
                                        <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                                      </button>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!--//////////////////////////////////////////////////////////////-->
                        <hr>
                        <!--//////////////////////////////////////////////////////////////-->
                        <div>
                          <h4 class="form-label">Bareme :</h4>
                          <div class="row">
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">Bareme</label>
                              <div :class="v.formBaremesA.val.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formBaremesA.val">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idbareme']+'|'+m['min-heures'] +'H-'+m['max-heures'] + 'H --> ' + m['tarif'] + 'DH' "
                                    v-for="m in Baremes"
                                    :key="m['idbareme']"
                                    :style="ajouterBaremesList.map(v=>v.idbareme).indexOf(m['idbareme'].toString())!=-1?'display:none':''"
                                    >{{m['min-heures'] +'H-'+m['max-heures'] + 'H --> ' + m['tarif'] + 'DH' }}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formBaremesA.val.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-2">
                            <br>
                            <button type="button" class="btn btn-primary float-end" @click="addBaremesJson">
                              Ajouter
                            </button>
                          </div>
                          </div>
                          <div style="overflow-x: auto;max-height: 500px;">
                            <table
                              class="table table-bordered table-striped dataTable"
                            >
                              <thead>
                                <tr>
                                  <th>Bareme</th>
                                </tr>
                              </thead>
                              <tbody>
                                  <tr v-if="ajouterBaremesList.length==0"><td colspan="2" align="center">Ajouter Bareme</td></tr>
                                  <tr v-for="d,i of ajouterBaremesList" :key="i">
                                    <td>{{d.designation}}</td>
                                    <td>
                                      <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="deleteBaremesJson(i)">
                                        <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                                      </button>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!--//////////////////////////////////////////////////////////////-->
                        <hr>
                        <!--//////////////////////////////////////////////////////////////-->
                        <div>
                          <h4 class="form-label">Periode :</h4>
                          <div class="row">
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">Periode</label>
                              <div :class="v.formPeriodesA.val.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formPeriodesA.val">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idperiode']+'|'+m['designation']"
                                    v-for="m in Periodes"
                                    :key="m['idperiode']"
                                    :style="ajouterPeriodesList.map(v=>v.idperiode).indexOf(m['idperiode'].toString())!=-1?'display:none':''"
                                    >{{m['designation']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formPeriodesA.val.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-2">
                            <br>
                            <button type="button" class="btn btn-primary float-end" @click="addPeriodesJson">
                              Ajouter
                            </button>
                          </div>
                          </div>
                          <div style="overflow-x: auto;max-height: 500px;">
                            <table
                              class="table table-bordered table-striped dataTable"
                            >
                              <thead>
                                <tr>
                                  <th>Periode</th>
                                </tr>
                              </thead>
                              <tbody>
                                  <tr v-if="ajouterPeriodesList.length==0"><td colspan="2" align="center">Ajouter Periode</td></tr>
                                  <tr v-for="d,i of ajouterPeriodesList" :key="i">
                                    <td>{{d.designation}}</td>
                                    <td>
                                      <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="deletePeriodesJson(i)">
                                        <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                                      </button>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!--//////////////////////////////////////////////////////////////-->
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalAdd"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addgroupe" @click="save">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Ajouter formule -->

        <!-- modal Modifier formule -->
        <div
          class="modal fade"
          id="UpdateModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">modifier formule</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body">
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Convention :</label>
                              <div :class="v.formU.ConventionId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.ConventionId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idconvention']"
                                    v-for="m in Conventions"
                                    :key="m['idconvention']"
                                    >{{m.organisme}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.ConventionId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Cadence hebdomodaire :</label>
                              <div :class="v.formU.cadencehebdoId.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.cadencehebdoId">
                                  <option :value="null" disabled>Choisie </option> 
                                  <option
                                  :value="m['idcadence-hebdo']"
                                    v-for="m in Cadences"
                                    :key="m['idcadence-hebdo']"
                                    >{{m['nbr-heures'] + " H/Semaine"}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.cadencehebdoId.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Tarif Frais d'inscription :</label>
                              <div :class="v.formU.tarif.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                 <input
                                  type="text"
                                  placeholder="Frais d'inscription..."
                                  class="form-control"
                                  v-model="formU.tarif"
                                  @input="v.formU.tarif.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.tarif.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalUpdate"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addgroupe" @click="Update">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier formule -->

        <!-- modal Supprimer formule -->
        <div
          class="modal fade"
          id="DeleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer formule</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalDelete"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce formule?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <label class="form-label">N Gr</label>
                    <input
                      type="text" 
                      class="form-control"
                      v-model="formD.idformule"
                    />
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalDelete">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deletegroupe" @click="Delete">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer groupe -->

        <!-- modal Categories prof -->
        <div
          class="modal fade"
          id="showCatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Categories</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowCats"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>designation</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupeCategories.length==0"><td colspan="1" align="center">Aucun periode trouve</td></tr>
                        <tr v-for="(d,i) of GroupeCategories" :key="i">
                          <td>{{d.designation}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="showDeleteCatsModal(d)" >
                              <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowCats">
                  fermer
                </button>
                <button @click="showAddCatsModal()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Categories -->

        <!-- modal add Categories prof -->
        <div
          class="modal fade"
          id="AddCatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter Categorie</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAddCatsModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Categorie</label>
                    <div :class="v.formCatsA2.idcategorie.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      <select class="form-select" v-model="formCatsA2.idcategorie">
                        <option :value="null" disabled>Choisie </option> 
                        <option
                        :value="m['idcategorie']"
                          v-for="m in Categories"
                          :key="m['idcategorie']"
                          :style="GroupeCategories.map(v=>v.idcategorie).indexOf(m['idcategorie'])!=-1?'display:none':''"
                          >{{m.designation}}</option> 
                      </select>
                    </div>
                    <div class="input-errors" v-for="error of v.formCatsA2.idcategorie.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAddCatsModal">
                  Non
                </button>
                <button @click="saveCats" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal add Categories prof -->

        <!-- modal Supprimer Categorie prof -->
        <div
          class="modal fade"
          id="DeleteCatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer Categorie</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeleteCatsModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce Categorie?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <strong class="form-label">Categorie : </strong>
                    {{formCatsD.idcategorie}}
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeleteCatsModal">
                  Non
                </button>
                <button @click="DeleteCats" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer matiers-tarif prof -->

        <!---------------------------------------------------------------------------------------------------------------------------------->

        <!-- modal Matieres prof -->
        <div
          class="modal fade"
          id="showMatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Matieres</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowMats"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>designation</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupeMatieres.length==0"><td colspan="1" align="center">Aucun Matiere trouve</td></tr>
                        <tr v-for="(d,i) of GroupeMatieres" :key="i">
                          <td>{{d.designation}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="showDeleteMatsModal(d)" >
                              <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowMats">
                  fermer
                </button>
                <button @click="showAddMatsModal()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Categories -->

        <!-- modal add Matieres prof -->
        <div
          class="modal fade"
          id="AddMatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter Matiere</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAddMatsModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Categorie</label>
                    <div :class="v.formMatsA2.idmatiere.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      <select class="form-select" v-model="formMatsA2.idmatiere">
                        <option :value="null" disabled>Choisie </option> 
                        <option
                        :value="m['idmatiere']"
                          v-for="m in Matieres"
                          :key="m['idmatiere']"
                          :style="GroupeMatieres.map(v=>v.idmatiere).indexOf(m['idmatiere'])!=-1?'display:none':''"
                          >{{m.designation}}</option> 
                      </select>
                    </div>
                    <div class="input-errors" v-for="error of v.formMatsA2.idmatiere.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAddMatsModal">
                  Non
                </button>
                <button @click="saveMats" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal add Matieres prof -->

        <!-- modal Supprimer Matieres prof -->
        <div
          class="modal fade"
          id="DeleteMatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer Matiere</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeleteMatsModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce Categorie?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <strong class="form-label">Categorie : </strong>
                    {{formMatsD.idmatiere}}
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeleteMatsModal">
                  Non
                </button>
                <button @click="DeleteMats" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer matiers-tarif prof -->

        <!---------------------------------------------------------------------------------------------------------------------------------->

        <!-- modal Baremes prof -->
        <div
          class="modal fade"
          id="showBaremesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Baremes</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowBaremes"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupeBaremes.length==0"><td colspan="1" align="center">Aucun Bareme trouve</td></tr>
                        <tr v-for="(m,i) of GroupeBaremes" :key="i">
                          <td>{{m['min-heures'] +'H-'+m['max-heures'] + 'H --> ' + m['tarif'] + 'DH'}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="showDeleteBaremesModal(m)" >
                              <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowBaremes">
                  fermer
                </button>
                <button @click="showAddBaremesModal()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Baremes -->

        <!-- modal add Baremes prof -->
        <div
          class="modal fade"
          id="AddBaremesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter Bareme</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAddBaremesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Bareme</label>
                    <div :class="v.formBaremesA2.idbareme.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      <select class="form-select" v-model="formBaremesA2.idbareme">
                        <option :value="null" disabled>Choisie </option> 
                        <option
                        :value="m['idbareme']"
                          v-for="m in Baremes"
                          :key="m['idbareme']"
                          :style="GroupeBaremes.map(v=>v.idbareme).indexOf(m['idbareme'])!=-1?'display:none':''"
                          >{{m['min-heures'] +'H-'+m['max-heures'] + 'H --> ' + m['tarif'] + 'DH'}}</option> 
                      </select>
                    </div>
                    <div class="input-errors" v-for="error of v.formBaremesA2.idbareme.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAddBaremesModal">
                  Non
                </button>
                <button @click="saveBaremes" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal add Baremes prof -->

        <!-- modal Supprimer Baremes prof -->
        <div
          class="modal fade"
          id="DeleteBaremesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer Bareme</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeleteBaremesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce Bareme?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <strong class="form-label">Bareme : </strong>
                    {{formBaremesD.idbareme}}
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeleteBaremesModal">
                  Non
                </button>
                <button @click="DeleteBaremes" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer Bareme prof -->

        <!---------------------------------------------------------------------------------------------------------------------------------->

        <!-- modal Periodes prof -->
        <div
          class="modal fade"
          id="showPeriodesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Periodes</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowPeriodes"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupePeriodes.length==0"><td colspan="1" align="center">Aucun Periode trouve</td></tr>
                        <tr v-for="(m,i) of GroupePeriodes" :key="i">
                          <td>{{m.designation}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="showDeletePeriodesModal(m)" >
                              <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowPeriodes">
                  fermer
                </button>
                <button @click="showAddPeriodesModal()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Periodes -->

        <!-- modal add Periodes prof -->
        <div
          class="modal fade"
          id="AddPeriodesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter Periode</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAddPeriodesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Periode</label>
                    <div :class="v.formPeriodesA2.idperiode.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      <select class="form-select" v-model="formPeriodesA2.idperiode">
                        <option :value="null" disabled>Choisie </option> 
                        <option
                        :value="m['idperiode']"
                          v-for="m in Periodes"
                          :key="m['idperiode']"
                          :style="GroupePeriodes.map(v=>v.idperiode).indexOf(m['idperiode'])!=-1?'display:none':''"
                          >{{m.designation}}</option> 
                      </select>
                    </div>
                    <div class="input-errors" v-for="error of v.formPeriodesA2.idperiode.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAddPeriodesModal">
                  Non
                </button>
                <button @click="savePerdiodes" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal add Periodes prof -->

        <!-- modal Supprimer Periodes prof -->
        <div
          class="modal fade"
          id="DeletePeriodesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer Periode</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeletePeriodesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce Periode?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <strong class="form-label">Periode : </strong>
                    {{formPeriodesD.idperiode}}
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeletePeriodesModal">
                  Non
                </button>
                <button @click="DeletePeriodes" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer Periode prof -->
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import formuleApi from "../../../api/formule";
import AnneeApi from "../../../api/annees";
import matiereApi from "../../../api/matiere";
import cadenceApi from "../../../api/cadence";
import conventionApi from "../../../api/convention";
import documentationApi from "../../../api/documentation";
import profApi from "../../../api/prof";
import categorieApi from "../../../api/categorie";
import baremeApi from "../../../api/bareme";

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
  },
  data() {
    return { 
      weekDays:['lundi','mardi','mercredi','jeudi','vendredi','samedi','dimanche'],
      loader:false,
      ajouterCatsList:[],
      ajouterMatsList:[],
      ajouterBaremesList:[],
      ajouterPeriodesList:[],
      Formules:[],  
      GroupeCategories:[],  
      GroupeBaremes:[],  
      GroupeMatieres:[],  
      GroupePeriodes:[],  
      Categories:[],  
      Baremes:[],  
      Periodes:[],
      Annees:[],  
      Matieres:[],
      Cadences:[], 
      Conventions:[], 
      Documentations:[], 
      Profs:[], 
      selectedId:null,
      formA:{ 
        ConventionId:null,  
        cadencehebdoId:null,  
        tarif:'', 
      },
      formU:{
        idformule:'',
        ConventionId:null,  
        cadencehebdoId:null,  
        tarif:'', 
      },
      formD:{ 
        idformule:'',
        ConventionId:null,  
        cadencehebdoId:null,  
        tarif:'', 
      },
      formCatsA:{
        cat:null,
      },
      formCatsA2:{
        idcategorie:null,
      },
      formCatsD:{ 
        idcategorie:null,
      },
      formBaremesA:{
        val:null,
      },
      formBaremesA2:{
        idbareme:null,
      },
      formBaremesD:{ 
        idbareme:null,
      },
      formPeriodesA:{
        val:null,
      },
      formPeriodesA2:{
        idperiode:null,
      },
      formPeriodesD:{ 
        idperiode:null,
      },
      formMatsA:{
        val:null,
      },
      formMatsA2:{
        idmatiere:null,
      },
      formMatsD:{ 
        idmatiere:null,
      },
      url:null,
    };
  },
  validations () {
    return { 
      formA:{ 
        cadencehebdoId:{ required },  
        ConventionId:{ required },  
        tarif:{ required }, 
      },
      formU:{ 
        cadencehebdoId:{ required },  
        ConventionId:{ required },  
        tarif:{ required }, 
      },
      formCatsA:{
        cat:{ required },
      },
      formCatsA2:{
        idcategorie:{ required },
      },
      formCatsD:{ 
        idcategorie:{ required },
      },
      formBaremesA:{
        val:{ required },
      },
      formBaremesA2:{
        idbareme:{ required },
      },
      formBaremesD:{ 
        idbareme:{ required },
      },
      formPeriodesA:{
        val:{ required },
      },
      formPeriodesA2:{
        idperiode:{ required },
      },
      formPeriodesD:{ 
        idperiode:{ required },
      },
      formMatsA:{
        val:{ required },
      },
      formMatsA2:{
        idmatiere:{ required },
      },
      formMatsD:{ 
        idmatiere:{ required },
      },
    }
  },
  mounted() {
    this.datatable();
    console.log("kf") 
  },
  methods: {
    addCatsJson(){
      if(this.v.formCatsA.$invalid){
        this.v.formCatsA.$touch();
      }
      else{
        this.ajouterCatsList.push({
          idcategorie:this.formCatsA.cat.split('|')[0],
          designation:this.formCatsA.cat.split('|')[1],
        })
        this.formCatsA={
          cat:null,
        };
      }
    },
    deleteCatsJson(i){
      this.ajouterCatsList.splice(i,1);
    },
    addMatsJson(){
      if(this.v.formMatsA.$invalid){
        this.v.formMatsA.$touch();
      }
      else{
        this.ajouterMatsList.push({
          idmatiere:this.formMatsA.val.split('|')[0],
          designation:this.formMatsA.val.split('|')[1],
        })
        this.formCatsA={
          val:null,
        };
      }
    },
    deleteMatsJson(i){
      this.ajouterMatsList.splice(i,1);
    },
    addBaremesJson(){
      if(this.v.formBaremesA.$invalid){
        this.v.formBaremesA.$touch();
      }
      else{
        this.ajouterBaremesList.push({
          idbareme:this.formBaremesA.val.split('|')[0],
          designation:this.formBaremesA.val.split('|')[1],
        })
        this.formBaremesA={
          val:null,
        };
      }
    },
    deleteBaremesJson(i){
      this.ajouterBaremesList.splice(i,1);
    },
    addPeriodesJson(){
      if(this.v.formPeriodesA.$invalid){
        this.v.formPeriodesA.$touch();
      }
      else{
        this.ajouterPeriodesList.push({
          idperiode:this.formPeriodesA.val.split('|')[0],
          designation:this.formPeriodesA.val.split('|')[1],
        })
        this.formPeriodesA={
          val:null,
        };
      }
    },
    deletePeriodesJson(i){
      this.ajouterPeriodesList.splice(i,1);
    },
    async loadData() {
      this.loader = true;
      await formuleApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Formules = r.data; 
            console.log(r.data)
            var t = $("#formules").DataTable();
            t.clear().rows.add(this.Formules).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
      await AnneeApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Annees = r.data; 
          }
        }
      });
      await matiereApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Matieres = r.data; 
          }
        }
      });
      await profApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Profs = r.data; 
          }
        }
      });
      await documentationApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Documentations = r.data; 
          }
        }
      });
      await cadenceApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Cadences = r.data; 
          }
        }
      });
      await conventionApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Conventions = r.data; 
          }
        }
      });
      await categorieApi.getAllCategories((r) => {
        if(r!=null){
          if(r.success==true){
            this.Categories = r.data; 
          }
        }
      });
      await baremeApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Baremes = r.data; 
          }
        }
      });
      await AnneeApi.getAllPeriodes((r) => {
        if(r!=null){
          if(r.success==true){
            this.Periodes = r.data; 
          }
        }
      });
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#formules").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          data: this.Formules,
          columns: [
            { data: "idformule" },
            { data: "designation" },  
            { data: "organisme" },  
            { data: "nbr-heures" },  
            { data: "tarif-frais-inscription" },  
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowCategories"> Categories </button>',
            },
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowMatieres"> Matieres </button>',
            },
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowBaremes"> Baremes </button>',
            },
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowPeriodes"> Periodes </button>',
            },
            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info"> <i title="Modifier" style="cursor:pointer;" class="ti-pencil  btnUpdate" aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger"><i title="Supprimer" style="cursor:pointer;" class="ti-trash btnDelete" aria-hidden="true"></i></button></div>',
            },
          ],
        });
      });
      const thisVue = this;
      //for show Categories
      $("#formules tbody ").on("click", ".btnShowCategories", function () {
        var t = $("#formules").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showCatsModal(data["idformule"]);
      });
      //for show Matieres
      $("#formules tbody ").on("click", ".btnShowMatieres", function () {
        var t = $("#formules").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showMatsModal(data["idformule"]);
      });
      //for show Baremes
      $("#formules tbody ").on("click", ".btnShowBaremes", function () {
        var t = $("#formules").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showBaremesModal(data["idformule"]);
      });
      //for show Periodes
      $("#formules tbody ").on("click", ".btnShowPeriodes", function () {
        var t = $("#formules").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showPeriodesModal(data["idformule"]);
      });
      //for update
      $("#formules tbody ").on("click", ".btnUpdate", function () {
        console.log("update");
       // $("#UpdateModal").modal();
       thisVue.UpdateModal()
        var t = $("#formules").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formU.idformule = data["idformule"];
        thisVue.formU.ConventionId = data["idconvention"]; 
        thisVue.formU.cadencehebdoId = data["idcadence-hebdo"]; 
        thisVue.formU.tarif = data["tarif-frais-inscription"]; 
        thisVue.UpdateModal();
      });

      //for delete
      $("#formules tbody ").on("click", ".btnDelete", function () {
        console.log("delete");
        var t = $("#formules").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#DeleteModal").modal();
        thisVue.DeleteModal()
        thisVue.formD.idformule = data["idformule"];
        thisVue.formD.ConventionId = data["idconvention"]; 
        thisVue.formD.cadencehebdoId = data["idcadence-hebdo"]; 
        thisVue.formD.tarif = data["tarif-frais-inscription"]; 
      });
    },
    //////////////////////////////////////////////
    async loadCatsData() {
      console.log(this.selectedId)
      await formuleApi.getGroupeCategoriesByIdGroupe({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupeCategories = r.data; 
            console.log(r.data)
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showCatsModal(id) {
      this.selectedId = id;
      $("#showCatsModal").modal("show");
      this.loadCatsData();
    },
    closeModalshowCats() {
      $("#showCatsModal").modal("hide");
    },
    showDeleteCatsModal(d) {
      this.formCatsD.idcategorie = d['idcategorie'];
      $("#DeleteCatsModal").modal("show");
    },
    closeDeleteCatsModal() {
      $("#DeleteCatsModal").modal("hide");
    },
    showAddCatsModal() {
      $("#AddCatsModal").modal("show");
    },
    closeAddCatsModal() {
      $("#AddCatsModal").modal("hide");
    },
    saveCats(){ 
      if(this.v.formCatsA2.$invalid){//
        this.v.formCatsA2.$touch();//
      }//
      else{
      formuleApi.createGroupeCategorie({
        categorie_idcategorie:this.formCatsA2.idcategorie,   
        formule_idformule:this.selectedId, 
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadCatsData();
            this.closeAddCatsModal()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    DeleteCats(){
      formuleApi.deleteGroupeCategorie({
        idcategorie:this.formCatsD.idcategorie, 
        idformule:this.selectedId,
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadCatsData();
            this.closeDeleteCatsModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    //////////////////////////////////////////////
    async loadMatsData() {
      console.log(this.selectedId)
      await formuleApi.getFormuleMatieresByIdFormule({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupeMatieres = r.data; 
            console.log(r.data)
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showMatsModal(id) {
      this.selectedId = id;
      $("#showMatsModal").modal("show");
      this.loadMatsData();
    },
    closeModalshowMats() {
      $("#showMatsModal").modal("hide");
    },
    showDeleteMatsModal(d) {
      this.formMatsD.idmatiere = d['idmatiere'];
      $("#DeleteMatsModal").modal("show");
    },
    closeDeleteMatsModal() {
      $("#DeleteMatsModal").modal("hide");
    },
    showAddMatsModal() {
      $("#AddMatsModal").modal("show");
    },
    closeAddMatsModal() {
      $("#AddMatsModal").modal("hide");
    },
    saveMats(){ 
      if(this.v.formMatsA2.$invalid){//
        this.v.formMatsA2.$touch();//
      }//
      else{
      formuleApi.createFormuleMatiere({
        matiere_idmatiere:this.formMatsA2.idmatiere,   
        formule_idformule:this.selectedId, 
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadMatsData();
            this.closeAddMatsModal()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    DeleteMats(){
      formuleApi.deleteFormuleMatiere({
        idmatiere:this.formMatsD.idmatiere, 
        idformule:this.selectedId,
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadMatsData();
            this.closeDeleteMatsModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    ///////////////////////////////////////////////////
    async loadBaremesData() {
      console.log(this.selectedId)
      await formuleApi.getFormuleBaremesByIdFormule({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupeBaremes = r.data; 
            console.log(r.data)
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showBaremesModal(id) {
      this.selectedId = id;
      $("#showBaremesModal").modal("show");
      this.loadBaremesData();
    },
    closeModalshowBaremes() {
      $("#showBaremesModal").modal("hide");
    },
    showDeleteBaremesModal(d) {
      this.formBaremesD.idbareme = d['idbareme'];
      $("#DeleteBaremesModal").modal("show");
    },
    closeDeleteBaremesModal() {
      $("#DeleteBaremesModal").modal("hide");
    },
    showAddBaremesModal() {
      $("#AddBaremesModal").modal("show");
    },
    closeAddBaremesModal() {
      $("#AddBaremesModal").modal("hide");
    },
    saveBaremes(){ 
      if(this.v.formBaremesA2.$invalid){//
        this.v.formBaremesA2.$touch();//
      }//
      else{
      formuleApi.createFormuleBareme({
        bareme_idbareme:this.formBaremesA2.idbareme,   
        formule_idformule:this.selectedId, 
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadBaremesData();
            this.closeAddBaremesModal()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    DeleteBaremes(){
      formuleApi.deleteFormuleBareme({
        idbareme:this.formBaremesD.idbareme, 
        idformule:this.selectedId,
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadBaremesData();
            this.closeDeleteBaremesModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    ///////////////////////////////////////////////////
    async loadPeriodesData() {
      console.log(this.selectedId)
      await formuleApi.getGroupePeriodesByIdGroupe({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupePeriodes = r.data; 
            console.log(r.data)
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showPeriodesModal(id) {
      this.selectedId = id;
      $("#showPeriodesModal").modal("show");
      this.loadPeriodesData();
    },
    closeModalshowPeriodes() {
      $("#showPeriodesModal").modal("hide");
    },
    showDeletePeriodesModal(d) {
      this.formPeriodesD.idperiode = d['idperiode'];
      $("#DeletePeriodesModal").modal("show");
    },
    closeDeletePeriodesModal() {
      $("#DeletePeriodesModal").modal("hide");
    },
    showAddPeriodesModal() {
      $("#AddPeriodesModal").modal("show");
    },
    closeAddPeriodesModal() {
      $("#AddPeriodesModal").modal("hide");
    },
    savePerdiodes(){ 
      if(this.v.formPeriodesA2.$invalid){//
        this.v.formPeriodesA2.$touch();//
      }//
      else{
        console.log({
        periode_idperiode:this.formPeriodesA2.idperiode,   
        formule_idformule:this.selectedId, 
       })
      formuleApi.createGroupePeriode({
        periode_idperiode:this.formPeriodesA2.idperiode,   
        formule_idformule:this.selectedId, 
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadPeriodesData();
            this.closeAddPeriodesModal()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    DeletePeriodes(){
      formuleApi.deleteGroupePeriode({
        idperiode:this.formPeriodesD.idperiode, 
        idformule:this.selectedId,
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadPeriodesData();
            this.closeDeletePeriodesModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    ///////////////////////////////////////////////////
    addModal() {
      $("#AddModal").modal("show");
    },
    closeModalAdd() {
      $("#AddModal").modal("hide");
    }, 
    UpdateModal() {
      $("#UpdateModal").modal("show");
    },
    closeModalUpdate() {
      $("#UpdateModal").modal("hide");
    },
    DeleteModal() {
      $("#DeleteModal").modal("show");
    },
    closeModalDelete() {
      $("#DeleteModal").modal("hide");
    },
    ClearModal(){},
    save(){ 
      if(this.v.formA.$invalid){//
        this.v.formA.$touch();//
      }//
      else{
      formuleApi.add({
        cadence_hebdo_idcadence_hebdo:this.formA.cadencehebdoId,
        convention_idconvention:this.formA.ConventionId, 
        tarif_frais_inscription:this.formA.tarif,  
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            let i = 0;
            let j = 0;
            let k = 0;
            let l = 0;
            let v1 = false;
            let v2 = false;
            let v3 = false;
            let v4 = false;
             for(const m of this.ajouterCatsList){
               i++;
               formuleApi.createGroupeCategorie({
                  categorie_idcategorie:m.idcategorie,   
                  formule_idformule:r.id, 
              },(r) => {
                console.log(r)
                if(r!=null)
                {
                  if(r.success==true){
                    if(i==this.ajouterCatsList.length){
                      v1 = true;
                    }
                    if(v1 && v2 && v3 && v4){
                      this.loadData();
                      this.ClearModal();
                      this.closeModalAdd()
                    }
                  }
                  else{
                    console.log("error",r.msg)
                  }
                }
              });
             }
             for(const m of this.ajouterMatsList){
               l++;
               formuleApi.createFormuleMatiere({
                  matiere_idmatiere:m.idmatiere,   
                  formule_idformule:r.id, 
              },(r) => {
                console.log(r)
                if(r!=null)
                {
                  if(r.success==true){
                    if(l==this.ajouterMatsList.length){
                      v4 = true;
                    }
                    if(v1 && v2 && v3 && v4){
                      this.loadData();
                      this.ClearModal();
                      this.closeModalAdd()
                    }
                  }
                  else{
                    console.log("error",r.msg)
                  }
                }
              });
             }
             for(const m of this.ajouterBaremesList){
               j++;
               formuleApi.createFormuleBareme({
                  bareme_idbareme:m.idbareme,   
                  formule_idformule:r.id, 
              },(r) => {
                console.log(r)
                if(r!=null)
                {
                  if(r.success==true){
                    if(j==this.ajouterBaremesList.length){
                      v2 = true;
                    }
                    if(v1 && v2 && v3 && v4){
                      this.loadData();
                      this.ClearModal();
                      this.closeModalAdd()
                    }
                  }
                  else{
                    console.log("error",r.msg)
                  }
                }
              });
             }
             for(const m of this.ajouterPeriodesList){
               k++;
               formuleApi.createGroupePeriode({
                  periode_idperiode:m.idperiode,   
                  formule_idformule:r.id, 
              },(r) => {
                console.log(r)
                if(r!=null)
                {
                  if(r.success==true){
                    if(k==this.ajouterPeriodesList.length){
                      v3 = true;
                    }
                    if(v1 && v2 && v3 && v4){
                      this.loadData();
                      this.ClearModal();
                      this.closeModalAdd()
                    }
                  }
                  else{
                    console.log("error",r.msg)
                  }
                }
              });
             }
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Update(){
      if(this.v.formU.$invalid){//
        this.v.formU.$touch();//
      }//
      else{
      formuleApi.update({
        id:this.formU.idformule, 
        cadence_hebdo_idcadence_hebdo:this.formU.cadencehebdoId,
        convention_idconvention:this.formU.ConventionId, 
        tarif_frais_inscription:this.formU.tarif, 
       },(r) => { 
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalUpdate()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Delete(){
      formuleApi.delete({
        id:this.formD.idformule, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalDelete()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
