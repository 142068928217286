<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Horaires</h4>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15">
                  <div class="clearfix">
                    <button
                      type="button"
                      class="waves-effect waves-light btn mb-5 bg-gradient-warning"
                      @click="addModal()"
                    >
                      + Ajouter
                    </button>
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="salles"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>jour</th> 
                            <th>horaire</th> 
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->

        <!-- modal Ajouter salle -->
        <div
          class="modal fade"
          id="AddModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter horaire</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">jour :</label>
                              <div :class="v.formA.jour.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <select class="form-select" v-model="formA.jour">
                                  <option :value="null" selected>Choisie jour</option>
                                  <option v-for="d,i of weekDays" :key="i" :value="i">{{d}}</option>
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.jour.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          
                          <label class="form-label">Heure de debut :</label>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div :class="v.formA.heure_debut.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'" style="display:flex;align-items:center">
                                
                                <input
                                  type="number"
                                  class="form-control"
                                  min="0"
                                  max="23"
                                  v-model="formA.heure_debut"
                                  @input="v.formA.heure_debut.$touch()"
                                /> <span class="form-label" style="padding:5px;font-weight:bold">H</span>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.heure_debut.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div :class="v.formA.min_debut.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'" style="display:flex;align-items:center">
                                
                                <input
                                  type="number"
                                  class="form-control"
                                  min="0"
                                  max="59"
                                  v-model="formA.min_debut"
                                  @input="v.formA.min_debut.$touch()"
                                /> <span class="form-label" style="padding:5px;font-weight:bold">MIN</span>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.min_debut.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <label class="form-label">Heure de fin :</label>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div :class="v.formA.heure_fin.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'" style="display:flex;align-items:center">
                                
                                <input
                                  type="number"
                                  class="form-control"
                                  min="0"
                                  max="23"
                                  v-model="formA.heure_fin"
                                  @input="v.formA.heure_fin.$touch()"
                                /> <span class="form-label" style="padding:5px;font-weight:bold">H</span>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.heure_fin.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div :class="v.formA.min_fin.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'" style="display:flex;align-items:center">
                                
                                <input
                                  type="number"
                                  class="form-control"
                                  min="0"
                                  max="59"
                                  v-model="formA.min_fin"
                                  @input="v.formA.min_fin.$touch()"
                                /> <span class="form-label" style="padding:5px;font-weight:bold">MIN</span>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.min_fin.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalAdd"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addsalle" @click="save">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Ajouter salle -->

        <!-- modal Modifier salle -->
        <div
          class="modal fade"
          id="UpdateModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">modifier horaire</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body">
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">jour :</label>
                              <div :class="v.formU.jour.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <select class="form-select" v-model="formU.jour">
                                  <option :value="null" selected>Choisie jour</option>
                                  <option v-for="d,i of weekDays" :key="i" :value="i">{{d}}</option>
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.jour.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          
                          <label class="form-label">Heure de debut :</label>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div :class="v.formU.heure_debut.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'" style="display:flex;align-items:center">
                                
                                <input
                                  type="number"
                                  class="form-control"
                                  min="0"
                                  max="23"
                                  v-model="formU.heure_debut"
                                  @input="v.formU.heure_debut.$touch()"
                                /> <span class="form-label" style="padding:5px;font-weight:bold">H</span>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.heure_debut.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div :class="v.formU.min_debut.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'" style="display:flex;align-items:center">
                                
                                <input
                                  type="number"
                                  class="form-control"
                                  min="0"
                                  max="59"
                                  v-model="formU.min_debut"
                                  @input="v.formU.min_debut.$touch()"
                                /> <span class="form-label" style="padding:5px;font-weight:bold">MIN</span>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.min_debut.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>

                          <label class="form-label">Heure de fin :</label>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div :class="v.formU.heure_fin.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'" style="display:flex;align-items:center">
                                
                                <input
                                  type="number"
                                  class="form-control"
                                  min="0"
                                  max="23"
                                  v-model="formU.heure_fin"
                                  @input="v.formU.heure_fin.$touch()"
                                /> <span class="form-label" style="padding:5px;font-weight:bold">H</span>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.heure_fin.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div :class="v.formU.min_fin.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'" style="display:flex;align-items:center">
                                
                                <input
                                  type="number"
                                  class="form-control"
                                  min="0"
                                  max="59"
                                  v-model="formU.min_fin"
                                  @input="v.formU.min_fin.$touch()"
                                /> <span class="form-label" style="padding:5px;font-weight:bold">MIN</span>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.min_fin.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalUpdate"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addsalle" @click="Update">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier salle -->

        <!-- modal Supprimer salle -->
        <div
          class="modal fade"
          id="DeleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer horaire</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalDelete"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce horaire?</h4>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalDelete">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deletesalle" @click="Delete">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer salle -->
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import horaireApi from "../../../api/horaire";

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
  },
  data() {
    return { 
      weekDays:['lundi','mardi','mercredi','jeudi','vendredi','samedi','dimanche'],
      loader:false,
      Salles:[],  
      formA:{
        jour:null,
        heure_debut:0,
        min_debut:0,
        heure_fin:0,
        min_fin:0,
      },
      formU:{
        idhoraire:'',
        jour:null,
        heure_debut:0,
        min_debut:0,
        heure_fin:0,
        min_fin:0,
      },
      formD:{ 
        idhoraire:'',
      },
      url:null,
    };
  },
  validations () {
    return { 
      formA:{
        jour:{ required },
        heure_debut:{ required },
        min_debut:{ required },
        heure_fin:{ required },
        min_fin:{ required },
      },
      formU:{ 
        jour:{ required },
        heure_debut:{ required },
        min_debut:{ required },
        heure_fin:{ required },
        min_fin:{ required },
      },
    }
  },
  mounted() {
    this.datatable();
    console.log("kf") 
  },
  methods: {
    async loadData() {
      this.loader = true;
      this.Salles = []; 
      await horaireApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Salles = []; 
            for (const h of r.data) {
              this.Salles.push({
                id:h.idhoraire,
                jour:this.weekDays[h.jour],
                jourIndex:h.jour,
                heure_debut:h['heure-debut'],
                min_debut:h['min-debut'],
                heure_fin:h['heure-fin'],
                min_fin:h['min-fin'],
                horaire: 'De ' + h['heure-debut'] +':'+h['min-debut'] + ' A ' + h['heure-fin'] +':'+h['min-fin'],
              })
            }
            console.log(r.data)
            var t = $("#salles").DataTable();
            t.clear().rows.add(this.Salles).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#salles").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          data: this.Salles,
          columns: [
            { data: "id" },
            { data: "jour" }, 
            { data: "horaire" }, 

            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info"> <i title="Modifier" style="cursor:pointer;" class="ti-pencil  btnUpdate" aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger"><i title="Supprimer" style="cursor:pointer;" class="ti-trash btnDelete" aria-hidden="true"></i></button></div>',
            },
          ],
        });
      });
      const thisVue = this;
      //for update
      $("#salles tbody ").on("click", ".btnUpdate", function () {
        console.log("update");
       // $("#UpdateModal").modal();
       thisVue.UpdateModal()
        var t = $("#salles").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formU.idhoraire = data["id"];
        thisVue.formU.jour = data["jourIndex"];
        thisVue.formU.min_debut = data["min_debut"]; 
        thisVue.formU.heure_debut = data["heure_debut"]; 
        thisVue.formU.min_fin = data["min_fin"]; 
        thisVue.formU.heure_fin = data["heure_fin"]; 
      });

      //for delete
      $("#salles tbody ").on("click", ".btnDelete", function () {
        console.log("delete");
        var t = $("#salles").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#DeleteModal").modal();
        thisVue.DeleteModal()
        thisVue.formD.idhoraire = data["id"];
      });
    },
    addModal() {
      $("#AddModal").modal("show");
    },
    closeModalAdd() {
      $("#AddModal").modal("hide");
    }, 
    UpdateModal() {
      $("#UpdateModal").modal("show");
    },
    closeModalUpdate() {
      $("#UpdateModal").modal("hide");
    },
    DeleteModal() {
      $("#DeleteModal").modal("show");
    },
    closeModalDelete() {
      $("#DeleteModal").modal("hide");
    },
    ClearModal(){},
    save(){ 
      if(this.v.formA.$invalid){//
        this.v.formA.$touch();//
      }//
      else{
      horaireApi.add({
        jour:this.formA.jour, 
        heure_debut:this.formA.heure_debut, 
        min_debut:this.formA.min_debut, 
        heure_fin:this.formA.heure_fin, 
        min_fin:this.formA.min_fin,
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalAdd()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Update(){
      if(this.v.formU.$invalid){//
        this.v.formU.$touch();//
      }//
      else{
      horaireApi.update({
        id:this.formU.idhoraire, 
        jour:this.formU.jour, 
        heure_debut:this.formU.heure_debut, 
        min_debut:this.formU.min_debut, 
        heure_fin:this.formU.heure_fin, 
        min_fin:this.formU.min_fin,   
       },(r) => { 
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalUpdate()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Delete(){
      horaireApi.delete({
        id:this.formD.idhoraire, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalDelete()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
