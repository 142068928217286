<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Utilisateurs</h4>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15">
                  <div class="clearfix">
                    <button
                      type="button"
                      class="waves-effect waves-light btn mb-5 bg-gradient-warning"
                      @click="addModal()"
                    >
                      + Ajouter
                    </button>
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="users"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>email</th> 
                            <th>role</th> 
                            <th>centre</th> 
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->

        <!-- modal Ajouter user -->
        <div
          class="modal fade"
          id="AddModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter user</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">email</label>
                              <div :class="v.formA.email.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="email..."
                                  class="form-control"
                                  v-model="formA.email"
                                  @input="v.formA.email.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.email.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">password</label>
                              <div :class="v.formA.password.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="password..."
                                  class="form-control"
                                  v-model="formA.password"
                                  @input="v.formA.password.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.password.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Role</label>
                              <div :class="v.formA.administrateur.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.administrateur"> 
                                  <option :value="null">
                                    choisir
                                  </option>
                                  <option v-for="r of Roles" :key="r.id" :value="r.id">
                                    {{r.role}}
                                  </option>
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.administrateur.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Centre</label>
                              <div :class="v.formA.centre.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formA.centre"> 
                                  <option :value="null">
                                    choisir
                                  </option>
                                  <option v-for="centre of Centres" :key="centre.id" :value="centre.id">
                                    {{centre.nom}}
                                  </option>
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formA.centre.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalAdd"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="adduser" @click="save">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Ajouter user -->

        <!-- modal Modifier user -->
        <div
          class="modal fade"
          id="UpdateModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">modifier user</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body">
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">email</label>
                              <div :class="v.formU.email.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="email..."
                                  class="form-control"
                                  v-model="formU.email"
                                  @input="v.formU.email.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.email.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Role</label>
                              <div :class="v.formU.administrateur.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.administrateur"> 
                                  <option :value="null">
                                    choisir
                                  </option>
                                  <option v-for="r of Roles" :key="r.id" :value="r.id">
                                    {{r.role}}
                                  </option>
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.administrateur.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Centre</label>
                              <div :class="v.formU.centre.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select" v-model="formU.centre"> 
                                  <option :value="null">
                                    choisir
                                  </option>
                                  <option v-for="centre of Centres" :key="centre.id" :value="centre.id">
                                    {{centre.nom}}
                                  </option>
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formU.centre.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalUpdate"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="adduser" @click="Update">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier user -->

  <!-- modal Modifier password -->
        <div
          class="modal fade"
          id="UpdatePasswordModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">modifier mot de passe</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body">
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">password</label>
                              <div :class="v.formUP.password.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="password..."
                                  class="form-control"
                                  v-model="formUP.password"
                                  @input="v.formUP.password.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formUP.password.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>  
                          </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalUpdatePassword"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="UpdatePassword" @click="UpdatePassword">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier password -->
        <!-- modal Supprimer user -->
        <div
          class="modal fade"
          id="DeleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer user</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalDelete"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce user?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <label class="form-label">email</label>
                    <input
                      type="text" 
                      class="form-control"
                      v-model="formD.email"
                    />
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalDelete">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deleteuser" @click="Delete">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer user -->
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import UserApi from "../../../api/user";
import CentreApi from "../../../api/centre";

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
  },
  data() {
    return { 
      loader:false,
      Users:[],  
      Centres:[],
      Roles:[],
      formA:{
        email:'',
        password:'',
        administrateur:null,
        centre:null,
      },
      formU:{
        id:'',
        email:'', 
        administrateur:null,
        centre:null, 
      },
      formUP:{ 
        id:'',
        password:'', 
      },
      formD:{ 
        id:'',
        email:'', 
      },
      url:null,
    };
  },
  validations () {
    return { 
      formA:{ 
        email:{ required },
        password:{ required },
        administrateur:{ required },
        centre:{ required },
      },
      formU:{ 
        email:{ required }, 
        administrateur:{ required },
        centre:{ required },
      },
      formUP:{ 
        password:{ required },  
      },
    }
  },
  mounted() {
    this.datatable();
    console.log("kf") 
    CentreApi.getAll((r)=>{
        if(r!=null){
          this.Centres=r.data;
        }
    })
    UserApi.getAllRoles((r)=>{
        if(r!=null){
          this.Roles=r.data;
        }
    })
  },
  methods: {
    async loadData() {
      this.loader = true;
      await UserApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Users = r.data; 
            console.log(r.data)
            var t = $("#users").DataTable();
            t.clear().rows.add(this.Users).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#users").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          data: this.Users,
          columns: [
            { data: "id" },
            { data: "email" }, 
            { data: "role" }, 
            { data: "nom" }, 

            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info  btnUpdate"> <i title="Modifier" style="cursor:pointer;" class="ti-pencil" aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger btnDelete"><i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-primary btnUpdatePassword"><i title="Modifier mot de passe" style="cursor:pointer;" class="ti-lock" aria-hidden="true"></i></button></div>',
            },
          ],
        });
      });
      const thisVue = this;
      //for update
      $("#users tbody ").on("click", ".btnUpdate", function () {
        console.log("update");
       // $("#UpdateModal").modal();
       thisVue.UpdateModal()
        var t = $("#users").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formU.id= data["id"];
        thisVue.formU.email = data["email"];  
        thisVue.formU.administrateur = data["administrateur"]; 
        thisVue.formU.centre = data["centre"]; 
      });

      //for delete
      $("#users tbody ").on("click", ".btnDelete", function () {
        console.log("delete");
        var t = $("#users").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#DeleteModal").modal();
        thisVue.DeleteModal()
        thisVue.formD.id = data["id"];
        thisVue.formD.email = data["email"]; 
      });

      //for btnUpdatePassword
        $("#users tbody ").on("click", ".btnUpdatePassword", function () {
        var t = $("#users").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#UpdatePasswordModal").modal();
        thisVue.UpdatePasswordModal()
        thisVue.formUP.id = data["id"];
      });
    },
    addModal() {
      $("#AddModal").modal("show");
    },
    closeModalAdd() {
      $("#AddModal").modal("hide");
    }, 
    UpdateModal() {
      $("#UpdateModal").modal("show");
    },
    closeModalUpdate() {
      $("#UpdateModal").modal("hide");
    },
    DeleteModal() {
      $("#DeleteModal").modal("show");
    },
    UpdatePasswordModal() {
      $("#UpdatePasswordModal").modal("show");
    },
    closeModalDelete() {
      $("#DeleteModal").modal("hide");
    },
    closeModalUpdatePassword() {
      $("#UpdatePasswordModal").modal("hide");
    },
    ClearModal(){},
    save(){ 
      console.log({
        email:this.formA.email,     
        administrateur:this.formA.administrateur,   
        password:this.formA.password,   
        centre:this.formA.centre,   
       })
      UserApi.add({
        email:this.formA.email,     
        administrateur:this.formA.administrateur,   
        password:this.formA.password,   
        centre:this.formA.centre,   
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalAdd()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    
    },
    Update(){
      if(this.v.formU.$invalid){//
        this.v.formU.$touch();//
      }//
      else{
      UserApi.update({
        id:this.formU.id, 
        email:this.formU.email,   
        administrateur:this.formU.administrateur,   
        centre:this.formU.centre,   
       },(r) => { 
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalUpdate()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    UpdatePassword(){
      if(this.v.formUP.$invalid){//
        this.v.formUP.$touch();//
      }//
      else{
      UserApi.updateUserPassword({
        id:this.formUP.id, 
        password:this.formUP.password,      
       },(r) => { 
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalUpdatePassword()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Delete(){
      UserApi.delete({
        id:this.formD.id, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalDelete()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
