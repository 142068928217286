<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Annees</h4>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15">
                  <div class="clearfix">
                    <button
                      type="button"
                      class="waves-effect waves-light btn mb-5 bg-gradient-warning"
                      @click="addModal()"
                    >
                      + Ajouter
                    </button>
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="annees"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>designation</th> 
                            <th>Date debut</th> 
                            <th>Date fin</th> 
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->

        <!-- modal Ajouter annee -->
        <div
          class="modal fade"
          id="AddModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter annee</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">designation</label>
                              <div :class="v.formA.designation.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <input
                                  type="text"
                                  placeholder="designation..."
                                  class="form-control"
                                  v-model="formA.designation"
                                  @input="v.formA.designation.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.designation.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date debut :</label>
                              <div :class="v.formA.debut.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <input
                                  type="date" 
                                  class="form-control"
                                  v-model="formA.debut"
                                  @input="v.formA.debut.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.debut.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date fin</label>
                              <div :class="v.formA.fin.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <input
                                  type="date" 
                                  class="form-control"
                                  v-model="formA.fin"
                                  @input="v.formA.fin.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formA.fin.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                        <hr>
                        <!--//////////////////////////////////////////////////////////////-->
                        <div>
                          <h4 class="form-label">Periodes :</h4>
                          <div class="row">
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">designation</label>
                              <div :class="v.formPeriodesA.designation.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <input
                                  type="text"  
                                  placeholder="designation..."
                                  class="form-control"
                                  v-model="formPeriodesA.designation"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formPeriodesA.designation.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">date debut</label>
                              <div :class="v.formPeriodesA.debut.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <input
                                  type="date"  
                                  class="form-control"
                                  v-model="formPeriodesA.debut"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formPeriodesA.debut.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <label class="form-label">date fin</label>
                              <div :class="v.formPeriodesA.fin.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <input
                                  type="date"  
                                  class="form-control"
                                  v-model="formPeriodesA.fin"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formPeriodesA.fin.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-2">
                            <br>
                            <button type="button" class="btn btn-primary float-end" @click="addPeriodesJson">
                              Ajouter
                            </button>
                          </div>
                          </div>
                          <div style="overflow-x: auto;max-height: 500px;">
                            <table
                              class="table table-bordered table-striped dataTable"
                            >
                              <thead>
                                <tr>
                                  <th>Designation</th>
                                  <th>Date debut</th> 
                                  <th>Date fin</th> 
                                </tr>
                              </thead>
                              <tbody>
                                  <tr v-if="ajouterPeriodesList.length==0"><td colspan="3" align="center">Ajouter periodes</td></tr>
                                  <tr v-for="d,i of ajouterPeriodesList" :key="i">
                                    <td>{{d.designation}}</td>
                                    <td>{{d.debut}}</td>
                                    <td>{{d.fin}}</td>
                                    <td>
                                      <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="deletePeriodesJson(i)">
                                        <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                                      </button>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!--//////////////////////////////////////////////////////////////-->

                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalAdd"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addannee" @click="save">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Ajouter annee -->

        <!-- modal Modifier annee -->
        <div
          class="modal fade"
          id="UpdateModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">modifier annee</h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body">
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">designation</label>
                              <div :class="v.formU.designation.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="designation..."
                                  class="form-control"
                                  v-model="formU.designation"
                                  @input="v.formU.designation.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.designation.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date debut</label>
                              <div :class="v.formU.debut.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date" 
                                  class="form-control"
                                  v-model="formU.debut"
                                  @input="v.formU.debut.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.debut.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date fin</label>
                              <div :class="v.formU.fin.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date" 
                                  class="form-control"
                                  v-model="formU.fin"
                                  @input="v.formU.fin.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formU.fin.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeModalUpdate"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addannee" @click="Update">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier annee -->

        <!-- modal Supprimer annee -->
        <div
          class="modal fade"
          id="DeleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer annee</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalDelete"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce annee?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <label class="form-label">designation</label>
                    <input
                      type="text" 
                      class="form-control"
                      v-model="formD.designation"
                    />
                  </div>
                </div>
                </div>
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalDelete">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deleteannee" @click="Delete">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer annee -->
        
        <!--//////////////////////////////////////////////////////////////-->
        <!-- modal mqtiers-tqrif prof -->
        <div
          class="modal fade"
          id="showPeriodesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des periodes</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowPeriodes"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>designation</th>
                        <th>date debut</th> 
                        <th>date fin</th> 
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="AnnesPeriodes.length==0"><td colspan="3" align="center">Aucun periode trouve</td></tr>
                        <tr v-for="d of AnnesPeriodes" :key="d['idperiode']">
                          <td>{{d.designation}}</td>
                          <td>{{d.debut}}</td>
                          <td>{{d.fin}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info" @click="showUpdatePeriodesModal(d)">
                              <i title="Modifier" style="cursor:pointer;" class="ti-pencil" aria-hidden="true"></i>
                            </button> 
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="showDeletePeriodesModal(d)" >
                              <i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowPeriodes">
                  fermer
                </button>
                <button @click="showAddPeriodesModal()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal periodes -->

        <!-- modal Supprimer periodes -->
        <div
          class="modal fade"
          id="DeletePeriodesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer periode</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeletePeriodesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer ce periode?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <strong class="form-label">designation : </strong>
                    {{formPeriodesD.designation}}
                  </div>
                </div>
                <div class="col-12">
                  <div>
                    <strong class="form-label">Date debut : </strong>
                    {{formPeriodesD.debut}}
                  </div>
                </div>
                <div class="col-12">
                  <div>
                    <strong class="form-label">Date fin : </strong>
                    {{formPeriodesD.fin}}
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeletePeriodesModal">
                  Non
                </button>
                <button @click="DeletePeriode()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer periode -->

        <!-- modal update periode -->
        <div
          class="modal fade"
          id="UpdatePeriodesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modifier periodes</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeUpdatePeriodesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment modifier ce periode?</h4>
                <div class="row"> 
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">designation</label>
                    <div :class="v.formPeriodesU.designation.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      
                      <input
                        type="text" 
                        placeholder="designation..."
                        class="form-control"
                        v-model="formPeriodesU.designation"
                        @input="v.formPeriodesU.designation.$touch()"
                      />
                    </div>
                    <div class="input-errors" v-for="error of v.formPeriodesU.designation.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">date debut</label>
                    <div :class="v.formPeriodesU.debut.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      
                      <input
                        type="date" 
                        class="form-control"
                        v-model="formPeriodesU.debut"
                        @input="v.formPeriodesU.debut.$touch()"
                      />
                    </div>
                    <div class="input-errors" v-for="error of v.formPeriodesU.debut.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">date fin</label>
                    <div :class="v.formPeriodesU.fin.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      
                      <input
                        type="date" 
                        class="form-control"
                        v-model="formPeriodesU.fin"
                        @input="v.formPeriodesU.fin.$touch()"
                      />
                    </div>
                    <div class="input-errors" v-for="error of v.formPeriodesU.fin.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeUpdatePeriodesModal">
                  Non
                </button>
                <button @click="UpdatePeriode()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal update periode -->

        <!-- modal add periode -->
        <div
          class="modal fade"
          id="AddPeriodesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter periode</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAddPeriodesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">designation</label>
                    <div :class="v.formPeriodesA2.designation.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      
                      <input
                        type="text" 
                        placeholder="designation..."
                        class="form-control"
                        v-model="formPeriodesA2.designation"
                        @input="v.formPeriodesA2.designation.$touch()"
                      />
                    </div>
                    <div class="input-errors" v-for="error of v.formPeriodesA2.designation.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Date debut</label>
                    <div :class="v.formPeriodesA2.debut.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      
                      <input
                        type="date"  
                        class="form-control"
                        v-model="formPeriodesA2.debut"
                        @input="v.formPeriodesA2.debut.$touch()"
                      />
                    </div>
                    <div class="input-errors" v-for="error of v.formPeriodesA2.debut.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Date fin</label>
                    <div :class="v.formPeriodesA2.fin.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      
                      <input
                        type="date"   
                        class="form-control"
                        v-model="formPeriodesA2.fin"
                        @input="v.formPeriodesA2.fin.$touch()"
                      />
                    </div>
                    <div class="input-errors" v-for="error of v.formPeriodesA2.fin.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAddPeriodesModal">
                  Non
                </button>
                <button @click="savePeriode()" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal add periode -->
        <!--//////////////////////////////////////////////////////////////-->
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import AnneeApi from "../../../api/annees";

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
  },
  data() {
    return { 
      loader:false,
      Annees:[],  
      AnnesPeriodes:[],
      ajouterPeriodesList:[],
      selectedId:null,
      formA:{
        designation:'',
        debut:'',
        fin:'',
      },
      formU:{
        idannee:'',
        designation:'', 
        debut:'',
        fin:'',
      },
      formD:{ 
        idannee:'',
        designation:'', 
        debut:'',
        fin:'',
      },
      formPeriodesA:{
        designation:'',
        debut:'',
        fin:'',
      },
      formPeriodesA2:{
        designation:'',
        debut:'',
        fin:'',
      },
      formPeriodesU:{
        id:'',
        designation:'',
        debut:'',
        fin:'',
      },
      formPeriodesD:{ 
        id:'',
        designation:'',
        debut:'',
        fin:'',
      },
      url:null,
    };
  },
  validations () {
    return { 
      formA:{
        designation:{ required },
        debut:{ required },
        fin:{ required },
      },
      formU:{ 
        designation:{ required }, 
        debut:{ required },
        fin:{ required },
      },
      formPeriodesA:{ 
        designation:{ required }, 
        debut:{ required },
        fin:{ required },
      },
      formPeriodesA2:{ 
        designation:{ required }, 
        debut:{ required },
        fin:{ required },
      },
      formPeriodesU:{ 
        designation:{ required }, 
        debut:{ required },
        fin:{ required },
      },
    }
  },
  mounted() {
    this.datatable();
    console.log("kf") 
  },
  methods: {
    addPeriodesJson(){
      if(this.v.formPeriodesA.$invalid){
        this.v.formPeriodesA.$touch();
      }
      else{
        this.ajouterPeriodesList.push({ 
          designation:this.formPeriodesA.designation,
          debut:this.formPeriodesA.debut,
          fin:this.formPeriodesA.fin,
        })
        this.formPeriodesA={ 
        designation:'',
        debut:'',
        fin:'', 
        };
      }
    },
    deletePeriodesJson(i){
      this.ajouterPeriodesList.splice(i,1);
    },
    async loadData() {
      this.loader = true;
      await AnneeApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Annees = r.data; 
            console.log(r.data)
            var t = $("#annees").DataTable();
            t.clear().rows.add(this.Annees).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#annees").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          data: this.Annees,
          columns: [
            { data: "idannee" },
            { data: "designation" }, 
            { data: "debut" }, 
            { data: "fin" }, 
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowPeriodes"> Periodes </button>',
            },
            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info btnUpdate"> <i title="Modifier" style="cursor:pointer;" class="ti-pencil" aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger btnDelete"><i title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i></button></div>',
            },
          ],
        });
      });
      const thisVue = this;

        //for show periodes
        $("#annees tbody ").on("click", ".btnShowPeriodes", function () {
        var t = $("#annees").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showPeriodesModal(data["idannee"]);
      });
      //for update
      $("#annees tbody ").on("click", ".btnUpdate", function () {
        console.log("update");
       // $("#UpdateModal").modal();
       thisVue.UpdateModal()
        var t = $("#annees").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formU.idannee = data["idannee"];
        thisVue.formU.designation = data["designation"]; 
        thisVue.formU.debut = data["debut"]; 
        thisVue.formU.fin = data["fin"]; 
      });

      //for delete
      $("#annees tbody ").on("click", ".btnDelete", function () {
        console.log("delete");
        var t = $("#annees").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#DeleteModal").modal();
        thisVue.DeleteModal()
        thisVue.formD.idannee = data["idannee"];
        thisVue.formD.designation = data["designation"]; 
      });
    },
    async loadPeriodesData() {
      console.log(this.selectedId)
      await AnneeApi.getAnneePeriodesByIdAnne({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.AnnesPeriodes = r.data; 
            console.log(r.data)
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    addModal() {
      $("#AddModal").modal("show");
    },
    closeModalAdd() {
      $("#AddModal").modal("hide");
    }, 
    UpdateModal() {
      $("#UpdateModal").modal("show");
    },
    closeModalUpdate() {
      $("#UpdateModal").modal("hide");
    },
    DeleteModal() {
      $("#DeleteModal").modal("show");
    },
    closeModalDelete() {
      $("#DeleteModal").modal("hide");
    },
    showPeriodesModal(id) {
      console.log("maaalk",id)
      this.selectedId = id;
      $("#showPeriodesModal").modal("show");
      this.loadPeriodesData();
    },
    closeModalshowPeriodes() {
      $("#showPeriodesModal").modal("hide");
    },
    showDeletePeriodesModal(d) {
      this.formPeriodesD.id = d['idperiode'];
      this.formPeriodesD.designation = d.designation;
      this.formPeriodesD.debut = d.debut;
      this.formPeriodesD.fin = d.fin;
      $("#DeletePeriodesModal").modal("show");
    },
    closeDeletePeriodesModal() {
      $("#DeletePeriodesModal").modal("hide");
    },
    showUpdatePeriodesModal(d) {
      this.formPeriodesU.id = d['idperiode'];
      this.formPeriodesU.designation = d.designation;
      this.formPeriodesU.debut = d.debut;
      this.formPeriodesU.fin = d.fin;
      $("#UpdatePeriodesModal").modal("show");
    },
    closeUpdatePeriodesModal() {
      $("#UpdatePeriodesModal").modal("hide");
    },
    showAddPeriodesModal() {
      $("#AddPeriodesModal").modal("show");
    },
    closeAddPeriodesModal() {
      $("#AddPeriodesModal").modal("hide");
    },
    ClearModal(){},
    savePeriode(){ 
      if(this.v.formPeriodesA2.$invalid){//
        this.v.formPeriodesA2.$touch();//
      }//
      else{
      AnneeApi.addPeriode({
        designation:this.formPeriodesA2.designation,   
        annee_idannee:this.selectedId,   
        debut:this.formPeriodesA2.debut, 
        fin:this.formPeriodesA2.fin, 
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
            this.loadPeriodesData();
            this.closeAddPeriodesModal()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    UpdatePeriode(){
      if(this.v.formPeriodesU.$invalid){//
        this.v.formPeriodesU.$touch();//
      }//
      else{
      AnneeApi.updatePeriode({
        id:this.formPeriodesU.id,   
        designation:this.formPeriodesU.designation,   
        annee_idannee:this.selectedId,   
        debut:this.formPeriodesU.debut, 
        fin:this.formPeriodesU.fin, 
       },(r) => { 
         if(r!=null)
         {
           if(r.success==true){
            this.loadPeriodesData();
            this.closeUpdatePeriodesModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    DeletePeriode(){
      AnneeApi.deletePeriode({
        id:this.formPeriodesD.id, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadPeriodesData();
            this.closeDeletePeriodesModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    save(){ 
      if(this.v.formA.$invalid){//
        this.v.formA.$touch();//
      }//
      else{
      AnneeApi.add({
        designation:this.formA.designation,   
        debut:this.formA.debut,   
        fin:this.formA.fin,   
       },(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
             let i = 0;
             for(const m of this.ajouterPeriodesList){
               i++;
              AnneeApi.addPeriode({
                designation:m.designation,   
                annee_idannee:r.id,   
                debut:m.debut, 
                fin:m.fin, 
              },(r) => {
                console.log(r)
                if(r!=null)
                {
                  if(r.success==true){
                    if(i==this.ajouterPeriodesList.length){
                      this.loadData();
                      this.ClearModal();
                      this.closeModalAdd()
                    }
                  }
                  else{
                    console.log("error",r.msg)
                  }
                }
              });
            }
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Update(){
      if(this.v.formU.$invalid){//
        this.v.formU.$touch();//
      }//
      else{
      AnneeApi.update({
        id:this.formU.idannee, 
        designation:this.formU.designation,   
        debut:this.formU.debut,   
        fin:this.formU.fin,   
       },(r) => { 
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalUpdate()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    }
    },
    Delete(){
      AnneeApi.delete({
        id:this.formD.idannee, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalDelete()
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
