import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
export default {
    getAll(callback){

        axios.get(myConfig.api_url+`/api/v1/allFormules?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getGroupeCategoriesByIdGroupe(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getFormuleCategoriesByIdFormule?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getFormuleBaremesByIdFormule(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getFormuleBaremesByIdFormule?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getGroupePeriodesByIdGroupe(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getFormulePeriodeByIdFormule?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getFormuleMatieresByIdFormule(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getFormuleMatieresByIdFormule?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //formule_idformule:?, categorie_idcategorie:?
    createGroupeCategorie(args,callback){

        axios.post(myConfig.api_url+`/api/v1/createFormuleCategorie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idformule:?, idcategorie:?
    updateGroupeCategorie(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateFormuleCategorie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idformule:?, idcategorie:?
    deleteGroupeCategorie(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteFormuleCategorie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //formule_idformule:?, bareme_idbareme:?
    createFormuleBareme(args,callback){

        axios.post(myConfig.api_url+`/api/v1/createFormuleBareme?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idformule:?, idbareme:?
    updateFormuleBareme(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateFormuleBareme?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //formule_idformule:?, idbareme:?
    deleteFormuleBareme(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteFormuleBareme?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //formule_idformule:?, periode_idperiode:?
    createGroupePeriode(args,callback){

        axios.post(myConfig.api_url+`/api/v1/createFormulePeriode?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idformule:?, idperiode:?
    updateGroupePeriode(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateFormulePeriode?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idformule:?, idperiode:?
    deleteGroupePeriode(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteFormulePeriode?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //formule_idformule:?, matiere_idmatiere:?
    createFormuleMatiere(args,callback){

        axios.post(myConfig.api_url+`/api/v1/createFormuleMatiere?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idformule:?, idmatiere:?
    updateFormuleMatiere(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateFormuleMatiere?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idformule:?, idmatiere:?
    deleteFormuleMatiere(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteFormuleMatiere?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    add(args,callback){

        //cadence_hebdo_idcadence_hebdo:?, convention_idconvention:?, tarif_frais_inscription:?
        axios.post(myConfig.api_url+`/api/v1/addFormule?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    update(args,callback){
        // id:?, cadence_hebdo_idcadence_hebdo:?, convention_idconvention:?, tarif_frais_inscription:?
        axios.post(myConfig.api_url+`/api/v1/updateFormule?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    delete(args,callback){
        //id:? ,
        axios.post(myConfig.api_url+`/api/v1/deleteFormule?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}